import React from 'react';
import cl from './ColoredIcon.module.scss';

const ColoredIcon = ({ color, className, children }) => {
  if (!color) color = 'blue';

  return (
    <div className={[cl.wrapper, cl?.[color], className].join(' ')}>
      {children}
    </div>
  );
};

export default ColoredIcon;
