import React, { useState } from 'react';
import moment from 'moment';
import google from '../../../../assets/svg/events/googleSvg.svg';
import outlook from '../../../../assets/svg/events/outlook.svg';
import download from '../../../../assets/picture/download.png';
import useBasicFuncs from '../../../../hooks/useBasicFuncs';
import styles from './AddCalendar.module.scss';
import { createEvent } from 'ics';
import { saveAs } from 'file-saver';

import {
  SVGaddCalendar,
  SVGapple,
  SVGgoogle,
  SVGoutlook,
} from '../../../../assets/PackageSVG';

function AddCalendar({ title, description, date }) {
  const { bdecode } = useBasicFuncs();
  const [state, setState] = useState(false);
  const handleToggle = () => {
    setState(!state);
  };
  const location = 'https://listingparty.com/events';

  function convertStringToDateArray(dateString) {
    const dateTime = moment(dateString);
    const year = dateTime.year();
    const month = dateTime.month() + 1; // Months are zero-based, so add 1
    const day = dateTime.date();
    const hour = dateTime.hour();
    const minute = dateTime.minute();

    return [year, month, day, hour, minute];
  }
  const event = {
    start: convertStringToDateArray(date),
    duration: { hours: 1 },
    title: `Listing Party - ${bdecode(title)}`,
    description: `${bdecode(description)}`,
    location: `${bdecode(location)}`,
    url: `${location}`,
  };

  const handleSave = () => {
    createEvent(event, (error, value) => {
      const blob = new Blob([value], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, 'event-schedule.ics');
    });
  };

  const subject = title;
  const startDt = date;
  const yearOutlook = date.slice(0, 4);
  const monthOutlook = date.slice(5, 7);
  const dayOutlook = date.slice(8, 10);
  const hourEndOutlook = Number(date.slice(11, 13)) + 1;
  const hourEndStringOutlook = hourEndOutlook.toString();
  const minuteOutlook = date.slice(14, 16);
  const secondOutlook = date.slice(17, 19);
  const endDateOutlook = `${yearOutlook}-${monthOutlook}-${dayOutlook}T${hourEndStringOutlook}:${minuteOutlook}:${secondOutlook}Z`;
  const endDt = endDateOutlook;
  const body = description;

  const convertToCustomFormat = (isoDate) => {
    // Extract year, month, day, hour, minute, and second from the input date
    const year = isoDate.slice(0, 4);
    const month = isoDate.slice(5, 7);
    const day = isoDate.slice(8, 10);
    const hour = isoDate.slice(11, 13);
    const hourEnd = Number(isoDate.slice(11, 13)) + 1;
    const hourEndString = hourEnd.toString();
    const minute = isoDate.slice(14, 16);
    const second = isoDate.slice(17, 19);

    // Format the date and time in the desired format

    const startDate = `${year}${month}${day}T${hour}${minute}${second}Z`;
    const endDate = `${year}${month}${day}T${hourEndString}${minute}${second}Z`;

    // Return the formatted date/time range
    return `${startDate}/${endDate}`;
  };
  return (
    <div
      className={styles.calendar}
      onClick={handleToggle}
      style={{ cursor: 'pointer' }}
    >
      <div className={styles.calendarTitle}>
        <span>
          {' '}
          <SVGaddCalendar />
        </span>
        <span style={{ whiteSpace: 'nowrap' }}>Add to Calendar</span>
      </div>
      {state && (
        <div className={styles.calendarItems}>
          <a
            href={`https://calendar.google.com/calendar/render?action=TEMPLATE&text=Listing Party - ${bdecode(
              title,
            )}&dates=${convertToCustomFormat(date)}&details=${bdecode(
              description,
            )}&location=https://listingparty.com/events`}
            target="_blank"
          >
            <div className={styles.calendarSection}>
              <img src={google} alt="google" />
              <p>Google</p>
            </div>
          </a>

          <a
            href={`https://outlook.live.com/calendar/0/deeplink/compose?subject=Listing Party - ${encodeURIComponent(
              subject,
            )}&startdt=${encodeURIComponent(
              startDt,
            )}&enddt=${encodeURIComponent(endDt)}&location=${encodeURIComponent(
              location,
            )}&body=${encodeURIComponent(body)}`}
            target="_blank"
          >
            <div className={styles.calendarSection}>
              <img src={outlook} alt="google" />
              <p> Outlook</p>
            </div>
          </a>
          <a onClick={handleSave}>
            <div className={styles.calendarSection}>
              <img src={download} />
              <p>Download event</p>
            </div>
          </a>
        </div>
      )}
    </div>
  );
}

export default AddCalendar;
