import { Link, useLocation } from 'react-router-dom';
import Logo from '../../assets/svg/splash/lp.svg';

import cl from './Header.module.scss';
import LogoApp from '../../assets/svg/logo_with_text.svg';

import {
  blockStateSelector,
  chatNotificationSelector,
  isAuthorizedSelector,
} from '../../redux/selectors';
import { useSelector } from 'react-redux';
import {
  SVGUpdatedLogoA,
  SVGUpdatedLogoB,
  SVGUpdatedLogoC,
  SVGprofilefont,
  SVGdirectoryfont,
  SVGinboxfont,
  SVGlistingsfont,
  SVGpartiesfont,
  SVGgoalsfont,
  SVGlogoutfont,
  SVGloader,
  SVGhomefont,
  SVGloginfont,
} from '../../assets/PackageSVG';

const LineLink = ({ to, name, children, isAuthorized }) => {
  const { pathname } = useLocation();
  const match = pathname.startsWith(to);

  const chatNotification = useSelector(chatNotificationSelector);

  return (
    <div className={cl.item}>
      <Link to={to}>
        <div
          className={[match ? cl.activeLine : '', cl.line].join(' ')}
          style={{
            border: isAuthorized && 'none',
            padding: isAuthorized && '0',
            backgroundColor: isAuthorized && 'transparent',
          }}
        >
          {children}
          <span
            style={{
              color: isAuthorized && match ? '#000' : isAuthorized && '#363636',
            }}
          >
            {name}
          </span>
          {name === 'Inbox' &&
            chatNotification &&
            !pathname.includes('chat') && (
              <div className={cl.inboxNotification}></div>
            )}
        </div>
      </Link>
    </div>
  );
};

//
//
//

const Header = () => {
  const isAuthorized = useSelector(isAuthorizedSelector);
  const blockState = useSelector(blockStateSelector);

  return (
    <div
      className={cl.wrapper}
      style={{
        backgroundColor: isAuthorized && '#fff',
        borderBottom: isAuthorized && 'grey',
      }}
    >
      <div className={cl.line}>
        <Link to="/">
          <div className={cl.logo}>
            {isAuthorized ? (
              <SVGUpdatedLogoA width={238} />
            ) : (
              <SVGUpdatedLogoC width={238} />
            )}
          </div>
        </Link>
        <div className={cl.menu}>
          {isAuthorized ? (
            <>
              <LineLink
                to="/profile"
                name="Profile"
                isAuthorized={isAuthorized}
              >
                <SVGprofilefont />
              </LineLink>

              {blockState?.noUsername === true ||
              blockState?.ban === true ? null : (
                <>
                  <LineLink
                    to="/directory"
                    name="Directory"
                    isAuthorized={isAuthorized}
                  >
                    <SVGdirectoryfont />
                  </LineLink>
                  <LineLink to="/chat" name="Inbox" isAuthorized={isAuthorized}>
                    <SVGinboxfont />
                  </LineLink>
                  <LineLink
                    to="/listings"
                    name="Listings"
                    isAuthorized={isAuthorized}
                  >
                    <SVGlistingsfont />
                  </LineLink>
                  <LineLink
                    to="/events"
                    name="Events"
                    isAuthorized={isAuthorized}
                  >
                    <SVGpartiesfont />
                  </LineLink>
                  <LineLink
                    to="/goals"
                    name="Goals"
                    isAuthorized={isAuthorized}
                  >
                    <SVGgoalsfont />
                  </LineLink>
                  <LineLink
                    to="/logout"
                    name="Logout"
                    isAuthorized={isAuthorized}
                  >
                    <SVGlogoutfont />
                  </LineLink>
                </>
              )}
            </>
          ) : (
            <>
              <LineLink to="/" name="Home" isAuthorized={isAuthorized}>
                {/* <SVGhomefont /> */}
              </LineLink>
              <LineLink to="/login" name="Login" isAuthorized={isAuthorized}>
                {/* <SVGloginfont /> */}
              </LineLink>
            </>
          )}
        </div>

        <div
          className={cl.rainbrow}
          style={{ visibility: isAuthorized && 'visible' }}
        ></div>
      </div>
    </div>
  );
};

export default Header;
