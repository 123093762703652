import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isProd: process.env.NODE_ENV === 'production',
  baseApiUrl: 'https://api.listperfectly.com/v1',
  baseAwsUrl: 'https://s3.amazonaws.com/cdn.listingparty.com',
};

const baseApiSlice = createSlice({
  name: 'baseApi',
  initialState,
});

export default baseApiSlice.reducer;
