import React, { useEffect, useState, useReducer } from 'react';

import _ from 'lodash';
import { toast } from 'react-toastify';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

import useUsers from '../../../hooks/useUsers';
import useScreenSize from '../../../hooks/useScreenSize';

import FormImage from './components/FormImage';
import Input from '../../../components/ui/input/Input';
import Toggle from '../../../components/ui/toggle/Toggle';
import Padlock from '../../../components/ui/padlock/Padlock';
import LPButton from '../../../components/ui/button/LPButton';
import Tooltip from '../../../components/ui/tooltip/Tooltip';

import cl from './AboutmeSect.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setBlockState } from '../../../redux/slices/bloskStateSlice';
import { userDataSelector } from '../../../redux/selectors';

const AboutmeSect = ({ saveBtnDisabled, setSaveBtnDisabled }) => {
  const [nameToggleChecked, setNameToggleChecked] = useState();
  const [formCountry, setFormCountry] = useState('');
  const [formRegion, setFormRegion] = useState('');
  const [locationToggleChecked, setLocationToggleChecked] = useState('');
  const [formPhotoData, setFormPhotoData] = useState({
    photo: {
      key: '',
    },
  });

  const [usernameSet, setUsernameSet] = useState(false);
  const [usernameDisabled, setUsernameDisabled] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [wrongUsername, setWrongUsername] = useState(false);
  const [currentUsername, setCurrentUsername] = useState('');

  //
  //
  //

  const { usersUpdate } = useUsers();
  const { isMobile } = useScreenSize();

  const userData = useSelector(userDataSelector);

  //
  //
  //

  const initialState = {
    formFirstName: '',
    formLastName: '',
    formUsername: '',
    formCity: '',
    formZip: '',
    formBio: '',
    formId: '',
  };
  const [state, dispatch] = useReducer(
    (state, action) => ({ ...state, ...action }),
    initialState,
  );

  //
  //
  //
  const dispatch_r = useDispatch();

  const updateAccountState = (uname) => {
    return dispatch_r(
      setBlockState({
        ban: userData?.status === 'suspended',
        noUsername: !uname,
      }),
    );
  };

  const runError = (error) => {
    let errMsg = _.get(error, 'response.data.message', undefined);
    if (errMsg && typeof errMsg === 'object') {
      //banned words case
      const bannedWords = errMsg?.words?.join() || null;
      if (bannedWords) {
        let msg = `Prohibited words: [${bannedWords}] detected, please remove and try again`;
        notifyError(msg);
      }
      //another error msg case
      const errorMsg = errMsg?.message || null;
      if (errorMsg) {
        notifyError(errorMsg.replace(/_/gi, ' '));
      }
    } else if (errMsg && typeof errMsg === 'string') {
      notifyError(errMsg.replace(/_/gi, ' '));
    } else if (!errMsg) notifyError();

    console.error('Error saving data', error.message);
  };

  //
  //
  //

  const onChangeUsername = ({ value }) => {
    if (!value.match(/^[a-z0-9_]*$/)) {
      setWrongUsername(true);
      return;
    }
    setSaveBtnDisabled((prev) => ({ ...prev, aboutme: false }));
    setWrongUsername(false);
    setUsernameSet(false);
    dispatch({ formUsername: value });
  };

  const trySaveUsername = () => {
    if (wrongUsername) return;
    if (
      state.formUsername &&
      state.formUsername.length > 0 &&
      state.formUsername.length <= 2
    ) {
      setWrongUsername(true);
      return;
    }
    if (!state.formUsername) {
      notifyNoUsername();
      return;
    }
    if (!usernameSet && state.formUsername !== currentUsername) {
      if (window.confirm('Are you sure you want to change your Party Name?')) {
        usersUpdate({
          payload: { username: state.formUsername },
          id: state.formId,
        }).then(
          ({ data }) => {
            notifyUsernameSaved(data.username);
            setCurrentUsername(data.username);
            setUsernameDisabled(true);
            updateAccountState(data.username);
          },
          (error) => {
            runError(error);
            dispatch({ formUsername: currentUsername });
            setUsernameDisabled(false);
          },
        );
      } else {
        return;
      }
    } else if (usernameSet && state.formUsername === currentUsername) {
      setUsernameDisabled((prev) => !prev);
      return;
    }
    setUsernameSet((prev) => !prev);
  };

  const onChangeFormHandler = ({ name, value }) => {
    setSaveBtnDisabled((prev) => ({ ...prev, aboutme: false }));
    if (name === 'first_name') {
      dispatch({ formFirstName: value });
    } else if (name === 'last_name') {
      dispatch({ formLastName: value });
    } else if (name === 'bio') {
      dispatch({ formBio: value });
    } else if (name === 'city') {
      dispatch({ formCity: value });
    } else if (name === 'zip') {
      dispatch({ formZip: value });
    }
  };

  const saveDataHandler = () => {
    if (wrongUsername) return;
    if (state.formUsername && state.formUsername.length <= 2) {
      setWrongUsername(true);
      notifyNoUsername();
      return;
    }
    if (!state.formUsername) {
      notifyNoUsername();
      return;
    }
    const id = { id: state.formId };
    const nameData = {
      name: {
        first_name: state.formFirstName,
        last_name: state.formLastName,
        public: nameToggleChecked,
      },
    };
    const locationData = {
      location: {
        city: state.formCity,
        state: formRegion,
        country: formCountry,
        zip: state.formZip,
        public: locationToggleChecked,
      },
    };
    const usernameData = {
      username: state.formUsername,
    };
    const bioData = {
      bio: state.formBio,
    };
    if (formPhotoData.photo === null) {
      formPhotoData.photo = {};
    }
    let aboutmeData = {
      payload: {
        ...formPhotoData,
        ...nameData,
        ...usernameData,
        ...locationData,
        ...bioData,
      },
      ...id,
    };
    if (state.formUsername !== currentUsername) {
      if (window.confirm(`Are you sure you want to change your Party Name?`)) {
        usersUpdate(aboutmeData).then(
          ({ data }) => {
            setSaveBtnDisabled((prev) => ({ ...prev, aboutme: true }));
            setUsernameSet(true);
            setCurrentUsername(data.username);
            setUsernameDisabled(true);
            notifySaving();
            notifyUsernameSaved(data.username);
            updateAccountState(data.username);
          },
          (error) => {
            runError(error);
            setUsernameSet((prev) => !prev);
          },
        );
      } else {
        return;
      }
    } else {
      usersUpdate(aboutmeData).then(
        () => {
          setSaveBtnDisabled((prev) => ({ ...prev, aboutme: true }));
          setUsernameDisabled(true);
          notifySaving();
          updateAccountState(state.formUsername);
        },
        (error) => {
          runError();
        },
      );
    }
  };

  //
  //
  //

  const notifySaving = () => {
    const toastPosition = isMobile ? 'top-center' : 'bottom-right';
    toast('Saved!', {
      position: toastPosition,
      autoClose: 1000,
      pauseOnFocus: false,
      pauseOnHover: false,
    });
  };
  const notifyError = (msg = 'Error saving data.') => {
    const toastPosition = isMobile ? 'top-center' : 'bottom-right';
    toast.warn(msg, {
      position: toastPosition,
      autoClose: 5000,
      draggable: true,
      hideProgressBar: true,
    });
  };
  const notifyNoUsername = () => {
    const toastPosition = isMobile ? 'top-right' : 'bottom-right';
    toast.warn("Let's add your username first!", {
      position: 'bottom-right',
      autoClose: 5000,
      draggable: true,
      hideProgressBar: true,
    });
  };
  const notifyUsernameSaved = (newUsername) => {
    const toastPosition = isMobile ? 'top-center' : 'bottom-right';
    toast.success(`Your new Party Name: ${newUsername} is saved!`, {
      position: toastPosition,
      autoClose: 5000,
      pauseOnFocus: false,
      pauseOnHover: false,
      hideProgressBar: true,
    });
  };

  let btnTitle = saveBtnDisabled.aboutme
    ? 'Please add information to the form first.'
    : 'Save form!';

  //
  //
  //

  useEffect(() => {
    if (userData) {
      const {
        name = {},
        location = {},
        bio = '',
        username = '',
        photo = {},
        id = '',
        types = [],
      } = userData;
      if (username) {
        setUsernameSet(true);
        setUsernameDisabled(true);
        setCurrentUsername(username);
      }
      //
      dispatch({ formFirstName: name?.first_name });
      dispatch({ formLastName: name?.last_name });
      setNameToggleChecked(name?.public);
      dispatch({ formUsername: username });
      setFormCountry(location?.country);
      setFormRegion(location.state);
      dispatch({ formCity: location.city });
      dispatch({ formZip: location.zip });
      setLocationToggleChecked(location?.public);
      dispatch({ formBio: _.unescape(bio) });
      dispatch({ formId: id });
      //
      setFormPhotoData({ photo: photo });
      setLoadingData(false);
      //
      updateAccountState(username);
    }
  }, [userData]);

  useEffect(() => {
    if (formPhotoData && formPhotoData?.photo?.key) {
      usersUpdate({ payload: { ...formPhotoData }, id: state.formId });
    }
  }, [formPhotoData]);

  return (
    <>
      <h2 className={cl.heading}>Profile</h2>
      <FormImage {...{ formPhotoData, setFormPhotoData }} />

      {/* name */}
      <div className={cl.aboutmeContainer}>
        <label htmlFor="name" className={cl.inputContainer}>
          <div className={cl.fieldNameDiv}>
            <span>Name</span>
          </div>
          <div className={cl.inputDiv}>
            <Input
              id="firstName"
              onChange={onChangeFormHandler}
              data={state.formFirstName}
              addClass="name"
              maxLength="32"
              required={true}
              name="first_name"
              placeholder="First"
              inputType="text"
            />
            <Input
              id="lastName"
              onChange={onChangeFormHandler}
              data={state.formLastName}
              addClass="name"
              maxLength="32"
              required={true}
              name="last_name"
              placeholder="Last"
              inputType="text"
            />
          </div>
        </label>
        <Toggle
          toggleChecked={nameToggleChecked}
          setToggleChecked={setNameToggleChecked}
          setSaveBtnDisabled={setSaveBtnDisabled}
        />

        {/* username */}
        <label htmlFor="username" className={cl.inputContainer}>
          <div className={cl.fieldNameDiv}>
            <span>Party name</span>
            <Tooltip>
              <p>
                Set your username that will display publicly. Pick something fun
                and party-like, use the name you have across social media or for
                branding. If you’re wanting privacy, we recommend choosing
                something that is not close to your real name.
              </p>
              <p>
                Party names can include uppercase or lowercase, letters,
                numbers, underscores. Spaces are not accepted. No offensive
                names are allowed.
              </p>
            </Tooltip>
          </div>
          <div className={[cl.inputDiv, cl.username].join(' ')}>
            <Input
              id="username"
              onChange={onChangeUsername}
              data={state.formUsername}
              addClass={usernameDisabled ? 'usernameDisabled' : 'username'}
              disabled={usernameDisabled ? 'disabled' : false}
              maxLength="32"
              required={true}
              name="username"
              placeholder="Party Name"
              inputType="text"
            />
            <div className={cl.padlock}>
              <Padlock
                {...{ usernameSet, trySaveUsername, usernameDisabled }}
              />
            </div>
            <span className={cl.usernameTip}>
              {wrongUsername
                ? 'Username should be more than 3 letters with no spaces, uppercase and special characters.'
                : null}
            </span>
          </div>
        </label>

        {/* location */}
        <label htmlFor="location" className={cl.inputContainer}>
          <div className={cl.fieldNameDiv}>
            <span>Location</span>
            <Tooltip>
              <p>
                Enter your physical location to help other partiers in your area
                find you. This information will help with searching for local
                assistants or connecting with other partiers.
              </p>
            </Tooltip>
          </div>

          <div className={cl.inputDiv}>
            <CountryDropdown
              className={cl.countryDropdown}
              value={formCountry}
              defaultOptionLabel="Country"
              showDefaultOption={true}
              priorityOptions={['US']}
              onChange={(val) => {
                setSaveBtnDisabled((prev) => ({ ...prev, aboutme: false }));
                setFormCountry(val);
              }}
            />
            <RegionDropdown
              className={cl.regionDropdown}
              country={formCountry}
              value={formRegion}
              blankOptionLabel="Region"
              disableWhenEmpty={true}
              defaultOptionLabel="Select Region"
              showDefaultOption={true}
              onChange={(val) => {
                setSaveBtnDisabled((prev) => ({ ...prev, aboutme: false }));
                setFormRegion(val);
              }}
            />
            <Input
              id="city"
              onChange={onChangeFormHandler}
              data={state.formCity}
              addClass="city"
              maxLength="32"
              required={false}
              name="city"
              placeholder="City"
              inputType="text"
            />
            <Input
              id="zip"
              onChange={onChangeFormHandler}
              data={state.formZip}
              addClass="zip"
              maxLength="10"
              required={false}
              name="zip"
              placeholder="Zip"
              inputType="text"
            />
          </div>
        </label>
        <div>
          <Toggle
            toggleChecked={locationToggleChecked}
            setToggleChecked={setLocationToggleChecked}
            setSaveBtnDisabled={setSaveBtnDisabled}
          />
        </div>

        <label htmlFor="bio" className={cl.inputContainer}>
          <div className={cl.fieldNameDiv}>
            <span>Bio</span>
            <Tooltip>
              <p>
                Tell us your reseller story. What do you want other partiers to
                know about you? When did you start selling? What’s the most
                unique item you’ve ever bought or sold? Have you ever sold
                anything to someone famous? Is there something funny,
                interesting or unusual about yourself?
              </p>
            </Tooltip>
          </div>
          <div className={cl.inputDiv}>
            <textarea
              id="bio"
              className={[cl.input, cl.bio].join(' ')}
              type="text"
              name="bio"
              data-key="bio"
              defaultValue={state.formBio ? state.formBio : ''}
              onBlur={(e) => onChangeFormHandler(e.target)}
            />
          </div>
        </label>
      </div>
      <LPButton
        disabled={saveBtnDisabled.aboutme}
        className={cl.saveBtn}
        onClick={saveDataHandler}
        title={btnTitle}
      >
        Save Profile!
      </LPButton>
    </>
  );
};

export default AboutmeSect;
