import LPButton from '../../components/ui/button/LPButton.jsx';
import { useNavigate } from 'react-router-dom';

import Container from '../../components/Container/Container.jsx';

import cl from './ErrorPage.module.scss';
//
//
//

const ErrorPage = ({ text, reason }) => {
  const navigate = useNavigate();

  return (
    <Container className={cl.wrapper}>
      <h2>We are working on things and will be back soon...</h2>
      {/* <div className={cl.text}>{text || 'Something went wrong'}</div> */}
      {/* 	{!reason ? null : (
				<div className={cl.reason}>
					<div>Why do I see this?</div>
					<div>{reason}</div>
				</div>
			)} */}

      <LPButton className={cl.btn_back} onClick={() => navigate('/')}>
        Back to Homepage
      </LPButton>
      {/* <img src={ErrorImg} alt='show error' /> */}
    </Container>
  );
};

//
//
//

export default ErrorPage;
