import React from 'react';
import { useState, useEffect, useRef } from 'react';
import cl from './Modal.module.scss';
import closeIcon from '../../assets/svg/cross.svg';
import leftArrow from '../../assets/svg/leftArrow.svg';
import rightArrow from '../../assets/svg/rightArrow.svg';

import Slider from 'react-slick';
import './Slider.css';
import { useSelector } from 'react-redux';
import { sliderIndexSelector } from '../../redux/selectors';

export default function ModalWindow({
  setIsModalWindow,
  isModalWindow,
  badgeLists,
  defaultLists,
}) {
  const sliderIndex = useSelector(sliderIndexSelector);

  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <span>
          {' '}
          <img src={rightArrow} alt="arrow" />
        </span>
      </div>
    );
  };
  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <span>
          <img src={leftArrow} alt="arrow" />
        </span>
      </div>
    );
  };

  const [imageIndex, setImageIndex] = useState(sliderIndex);

  function handleClose() {
    setIsModalWindow(false);
  }
  var settings = {
    infinite: true,
    autoplay: false,
    lazyLoad: false,
    centerMode: true,
    centerPadding: 0,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: sliderIndex,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div
        className={`${isModalWindow && 'overlay'}`}
        onClick={handleClose}
      ></div>
      <div className="windowContainer">
        <div className="header">
          <h2>Available badges</h2>
          <div onClick={handleClose} className="closeModal">
            <img src={closeIcon} alt="close" />
          </div>
        </div>

        <div className="slider">
          <Slider {...settings}>
            {defaultLists?.map((item, index) => (
              <div
                key={index}
                className={
                  index === imageIndex
                    ? 'slider-box activeSlider'
                    : 'slider-box'
                }
              >
                <div className="slider-box-col">
                  <div className="badge-img-box">
                    <img src={item.imgSrc} alt="" />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="badgesContent">
          {defaultLists?.map((item, index) => (
            <div
              key={index}
              className={index === imageIndex ? 'showText' : 'hideText'}
            >
              <div className={cl.nameContainer}>
                <h1>{item.title} Badge</h1>
                <h2 className={cl.whatIsIt}>What is it ?</h2>
                <p>{item.date}</p>
                <p style={{ fontWeight: 700 }}>{item.howToAdd}</p>
                <p style={{ marginBottom: 20 }}>
                  {React.createElement('span', {
                    dangerouslySetInnerHTML: {
                      __html: item.description,
                    },
                  })}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
    //  </div>
  );
}
