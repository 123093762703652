import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import MobileMenu from './MobileMenu';
import DesktopMenu from './DesktopMenu';

const algo = (url, arr) => arr.some((a) => url.startsWith(a));

const navigationSwitcher = (url) => {
  if (algo(url, ['/login', '/404', '/inbox'])) return 0;

  if (algo(url, ['/profile', '/listperf', '/goals'])) return 1;
  if (algo(url, ['/directory'])) return 2;
  if (algo(url, ['/listings'])) return 3;
  // if(algo(url, ['/events']))return 4;
  // if(algo(url, ['/goals']))return 3;

  return 0;
};

const Menu = ({ aMenuId, setMenuId, ...props }) => {
  const { pathname, hash, key } = useLocation();

  //

  useEffect(() => {
    const id = navigationSwitcher(pathname);
    if (aMenuId !== id) setMenuId(id);
  }, [pathname, aMenuId, setMenuId]);

  //

  useEffect(() => {
    if (hash === '') return window.scrollTo(0, 0);

    const id = hash.replace('#', '');
    const element = document.getElementById(id);

    let top = element?.getBoundingClientRect()?.y;
    top -= 99;
    window.scrollBy({ top: top || 0, behavior: 'smooth' });
  }, [pathname, hash, key]);

  //

  return (
    <>
      {props.isMobile ? (
        <>
          <MobileMenu {...props} aMenuId={aMenuId}></MobileMenu>
        </>
      ) : aMenuId !== 0 ? (
        <>
          <DesktopMenu {...props} aMenuId={aMenuId}></DesktopMenu>
        </>
      ) : null}
    </>
  );
};

export default Menu;
