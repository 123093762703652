import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';

import styles from './InviteMembersModal.module.scss';

import profilePic from '.././../../../../../assets/picture/profile.jpg';
import ModalButton from '../../../../../../components/ui/ModalButton/ModalButton';
import {
  SVGClose,
  SVGplus,
  SVGcheckmark,
} from '../../../../../../assets/PackageSVG';
import useUsers from '../../../../../../hooks/useUsers';
import useDebounce from '../../../../../../hooks/useDebounce';
import { useParams } from 'react-router-dom';
import { useGlobalContext } from '../../../../../../contexts/Context';

function InviteMembersModal(props) {
  const {
    handleToggleInviteMembersModal,
    getGroupMembers,
    userAdata,
    participants,
  } = props;

  const { state, dispatch } = useGlobalContext();

  const { chatItems, currentGroupItem } = state;

  const inviteMembersRef = useRef(null);

  const { usersList, groupUsersAdd } = useUsers();

  const { user_id } = useParams();

  const [foundedMembers, setFoundedMembers] = useState([]);
  const [groupMembers, setGroupMembers] = useState([]);

  const [filterBy, setFilterBy] = useState({
    search_everything: '',
  });

  const [metadata, setMetadata] = useState({ offset: 0, limit: 10 });

  const [options] = useState({
    include_badges: true,
  });

  const handleChangeSearchItem = (e) => {
    setFilterBy({ search_everything: e.target.value });
  };

  const debouncedSearchEverything = useDebounce(
    filterBy.search_everything,
    750
  );

  const handleInviteMember = async () => {
    try {
      const response = await usersList(filterBy, metadata, options);

      if (response.data) {
        setFoundedMembers(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (filterBy.search_everything) {
      handleInviteMember();
    }
  }, [debouncedSearchEverything]);

  const handleSendMemberToServer = async (id) => {
    try {
      const response = await groupUsersAdd(id, user_id);

      if (response.data) {
        groupMembers.forEach((item) => {
          if (item.id === response.data.user_id) {
            item.isAdded = true;
          }
        });

        const [tempChatUser] = groupMembers;

        const updatedChatItems = chatItems.map((item) => {
          if (item.id === user_id) {
            const item_users = _.get(item, 'users') || [];

            return { ...item, users: [...item_users, tempChatUser] };
          }
          return item;
        });

        dispatch({
          type: 'CHATITEMS',
          payload: updatedChatItems,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddGroupMember = (id) => {
    const isThere = groupMembers.some((item) => item.id === id);

    if (isThere || userAdata.id === id) {
      return;
    } else {
      const groupMember = foundedMembers?.find((item) => item.id === id);

      participants.forEach((item) => {
        if (item.user_id === id) {
          groupMember.isAdded = true;
        }
      });

      setGroupMembers([groupMember, ...groupMembers]);
    }

    setFilterBy({ search_everything: '' });
  };

  const USER_PHOTO = 'https://s3.us-east-1.amazonaws.com/cdn.listingparty.com/';

  const handleGetGroupMembers = () => {
    getGroupMembers();
    handleToggleInviteMembersModal();
  };

  return (
    <div className={styles.inviteMembersModal__overlay}>
      <div ref={inviteMembersRef} className={styles.modal}>
        <div className={styles.closeBtn}>
          <SVGClose onClick={handleGetGroupMembers} />
        </div>
        <h1>Invite Members</h1>
        <div className={styles.inviteMembersModalWrapper}>
          <div className={styles.inviteInputBlock}>
            <input
              type="text"
              onChange={handleChangeSearchItem}
              value={filterBy.search_everything}
              placeholder="Add member"
            />
          </div>
          {filterBy.search_everything.length === 0 ? (
            <div className={styles.groupMembers}>
              {groupMembers?.map((item) => (
                <div className={styles.inviteItemInfoWrapper}>
                  <div className={styles.itemInfo}>
                    <div className={styles.itemPhoto}>
                      <img
                        src={
                          item?.photo?.key
                            ? USER_PHOTO + item.photo.key
                            : profilePic
                        }
                        alt=""
                      />
                    </div>
                    <div className={styles.inviteItemName}>
                      <h3>
                        {item?.name?.first_name} {item?.name?.last_name}
                      </h3>
                      <span>@{item?.username}</span>
                    </div>
                  </div>
                  <ModalButton
                    feature={item.isAdded ? 'green' : 'light'}
                    cb={() => handleSendMemberToServer(item.id)}
                  >
                    <span>
                      {item.isAdded ? (
                        <SVGcheckmark
                          width={16}
                          height={16}
                          fill="currentColor"
                          style={{ paddingTop: '3px' }}
                        />
                      ) : (
                        <SVGplus />
                      )}
                    </span>
                    <span>{item?.isAdded ? 'Added' : 'Add to group'}</span>
                  </ModalButton>
                </div>
              ))}
            </div>
          ) : (
            <div className={styles.membersWrapper}>
              {foundedMembers?.map((item) => (
                <span onClick={() => handleAddGroupMember(item.id)}>
                  {item.username}
                </span>
              ))}
            </div>
          )}
        </div>
        <div className={styles.inviteModalButtonBlock}>
          {/* <ModalButton
                        feature="primary"
                        cb={handleGetGroupMembers}
                    >Save Changes</ModalButton> */}
        </div>
      </div>
    </div>
  );
}

export default InviteMembersModal;
