import React, { useState, useEffect } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';

import { useQuery } from '@tanstack/react-query';
import useUsers from '../../hooks/useUsers';

import activeCalendar from '../../assets/svg/events/activeCalendar.svg';
import activeEvents from '../../assets/svg/events/activeEvents.svg';
import calendar from '../../assets/svg/events/calendar.svg';
import eventsSvg from '../../assets/svg/events/events.svg';

import cl from './EventsPage.module.scss';

import UserSharedWorker from '../../hooks/UserSharedWorker';
import EventSelect from './components/EventSelect';
import { eventTypes } from './mock/eventSelectItems';
import { SVGcalendar, SVGlistview, SVGloader } from '../../assets/PackageSVG';

import Container from '../../components/Container/Container';
import EventCard from './EventCard';

import useBasicFuncs from '../../hooks/useBasicFuncs';
import EventModal from './components/EventModal';
import MyCalendar from './components/MyCalendar/';

import { setEventsTotal } from '../../redux/slices/eventsSlice';
import {
  setShowLink,
  setShowModal,
} from '../../redux/slices/eventTermsModalSlice';

//
import liveEvents from '../../assets/svg/liveEvents.svg';
import TermsModal from './components/TermsModal';
import { useDispatch, useSelector } from 'react-redux';
import { eventsTotalSelector } from '../../redux/selectors';

const EventsPage = () => {
  const dispatch = useDispatch();

  const { eventsList } = useUsers();

  const { bdecode } = useBasicFuncs();

  const eventsTotal = useSelector(eventsTotalSelector);

  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [tab, setTab] = useState('upcoming');

  const [metadata, setMetadata] = useState({ offset: 0, limit: 10 });
  const [options] = useState({
    include_hosts: true,
  });
  const [hasMore, setHasMore] = useState(true);
  const [isFetching, setIsFetching] = useState(true);
  const [typeLogic, setTypeLogic] = useState('events');
  //

  const [switcher, setSwitcher] = useState('events');

  const [types, setTypes] = useState([]);
  //

  const [calendarItems, setCalendarItems] = useState(null);

  const { worker } = UserSharedWorker();

  //

  const fetchData = () => {
    const now = new Date();
    let updatedTimestampStart = now.getTime() - 5400000;
    let updatedDateStart = new Date(updatedTimestampStart);
    const upComingDateStart = updatedDateStart.toISOString();
    try {
      eventsList(
        {
          date_range: {
            ...(tab === 'upcoming' && { start: upComingDateStart }),
            ...(tab === 'past' && { end: upComingDateStart }),
          },
          status: 'public',
          ...(types.length > 0 && { types: types }),
        },

        tab === 'upcoming'
          ? [{ column: 'date', order: 'asc' }]
          : [{ column: 'date', order: 'desc' }],

        options,
        metadata
      ).then((data) => {
        if (data?.data) {
          if (tab === 'upcoming') {
            setUpcomingEvents([...upcomingEvents, ...data.data]);
            setPastEvents([]);
          } else if (tab === 'past') {
            setPastEvents([...pastEvents, ...data.data]);
            setUpcomingEvents([]);
          }
          setHasMore(data.data.length > 0);

          let updOffset = metadata.offset + 10;
          setMetadata({ ...metadata, offset: updOffset });
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const emptyReguest__UpComingAll = () => {
    const now = new Date();
    let updatedTimestampStart = now.getTime() - 5400000;
    let updatedDateStart = new Date(updatedTimestampStart);
    const upComingDateStart = updatedDateStart.toISOString();

    try {
      eventsList(
        {
          date_range: {
            start: upComingDateStart,
          },
          status: 'public',
        },
        [{ column: 'date', order: 'asc' }],
        { include_hosts: true },
        {
          offset: 0,
          limit: 1,
        }
      ).then((data) => {
        dispatch(setEventsTotal({ upcomingAllTotal: data.metadata.total }));
      });
    } catch (error) {
      console.error(error);
    }
  };

  const emptyReguest__pastAll = () => {
    const now = new Date();
    let updatedTimestampStart = now.getTime() - 5400000;
    let updatedDateStart = new Date(updatedTimestampStart);
    const upComingDateStart = updatedDateStart.toISOString();
    try {
      eventsList(
        {
          date_range: {
            end: upComingDateStart,
          },
          status: 'public',
        },
        [{ column: 'date', order: 'asc' }],
        { include_hosts: true },
        { offset: 0, limit: 1 }
      ).then((data) => {
        dispatch(setEventsTotal({ pastAllTotal: data.metadata.total }));
      });
    } catch (error) {
      console.error(error);
    }
  };

  const emptyReguest__upcomingVirtual = () => {
    const now = new Date();
    let updatedTimestampStart = now.getTime() - 5400000;
    let updatedDateStart = new Date(updatedTimestampStart);
    const upComingDateStart = updatedDateStart.toISOString();

    try {
      eventsList(
        {
          date_range: {
            start: upComingDateStart,
          },
          status: 'public',
          types: ['virtual'],
        },
        [{ column: 'date', order: 'asc' }],
        { include_hosts: true },
        { offset: 0, limit: 1 }
      ).then((data) => {
        dispatch(setEventsTotal({ upcomingVirtualTotal: data.metadata.total }));
      });
    } catch (error) {
      console.error(error);
    }
  };

  const emptyReguest__pastVirtual = () => {
    const now = new Date();
    let updatedTimestampStart = now.getTime() - 5400000;
    let updatedDateStart = new Date(updatedTimestampStart);
    const upComingDateStart = updatedDateStart.toISOString();

    try {
      eventsList(
        {
          date_range: {
            end: upComingDateStart,
          },
          status: 'public',
          types: ['virtual'],
        },
        [{ column: 'date', order: 'asc' }],
        { include_hosts: true },
        { offset: 0, limit: 1 }
      ).then((data) => {
        dispatch(setEventsTotal({ pastVirtualTotal: data.metadata.total }));
      });
    } catch (error) {
      console.error(error);
    }
  };

  const emptyReguest__upcomingInPerson = () => {
    const now = new Date();
    let updatedTimestampStart = now.getTime() - 5400000;
    let updatedDateStart = new Date(updatedTimestampStart);
    const upComingDateStart = updatedDateStart.toISOString();

    try {
      eventsList(
        {
          date_range: {
            start: upComingDateStart,
          },
          status: 'public',
          types: ['in_person'],
        },
        [{ column: 'date', order: 'asc' }],
        { include_hosts: true },
        { offset: 0, limit: 1 }
      ).then((data) => {
        dispatch(
          setEventsTotal({ upcomingInPersonTotal: data.metadata.total })
        );
      });
    } catch (error) {
      console.error(error);
    }
  };

  const emptyReguest__pastInPerson = () => {
    const now = new Date();
    let updatedTimestampStart = now.getTime() - 5400000;
    let updatedDateStart = new Date(updatedTimestampStart);
    const upComingDateStart = updatedDateStart.toISOString();
    try {
      eventsList(
        {
          date_range: {
            end: upComingDateStart,
          },
          status: 'public',
          types: ['in_person'],
        },
        [{ column: 'date', order: 'asc' }],
        { include_hosts: true },
        { offset: 0, limit: 1 }
      ).then((data) => {
        dispatch(setEventsTotal({ pastInPersonTotal: data.metadata.total }));
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeSwitcher = (type) => {
    setTypeLogic(type);
    if (type === 'calendar') {
      emptyReguest__UpComingAll();
      emptyReguest__pastAll();

      // virtual
      emptyReguest__upcomingVirtual();
      emptyReguest__pastVirtual();

      // in_person
      emptyReguest__upcomingInPerson();
      emptyReguest__pastInPerson();
    }
    setSwitcher(type);
  };

  const MY_TOTAL =
    switcher === 'calendar' && tab === 'upcoming' && types.length === 0
      ? eventsTotal.upcomingAllTotal
      : switcher === 'calendar' && tab === 'past' && types.length === 0
      ? eventsTotal.pastAllTotal
      : switcher === 'calendar' && tab === 'upcoming' && types[0] === 'virtual'
      ? eventsTotal.upcomingVirtualTotal
      : switcher === 'calendar' && tab === 'past' && types[0] === 'virtual'
      ? eventsTotal.pastVirtualTotal
      : switcher === 'calendar' &&
        tab === 'upcoming' &&
        types[0] === 'in_person'
      ? eventsTotal.upcomingInPersonTotal
      : switcher === 'calendar' && tab === 'past' && types[0] === 'in_person'
      ? eventsTotal.pastInPersonTotal
      : switcher === 'events' && 10;

  const getEvents = () => {
    setIsFetching(true);

    const now = new Date();
    let updatedTimestampStart = now.getTime() - 5400000;
    let updatedDateStart = new Date(updatedTimestampStart);
    const upComingDateStart = updatedDateStart.toISOString();

    try {
      eventsList(
        {
          date_range: {
            ...(tab === 'upcoming' && { start: upComingDateStart }),
            ...(tab === 'past' && { end: upComingDateStart }),
          },
          status: 'public',
          ...(types.length > 0 && { types: types }),
        },

        tab === 'upcoming'
          ? [{ column: 'date', order: 'asc' }]
          : [{ column: 'date', order: 'desc' }],

        options,

        { offset: 0, limit: MY_TOTAL || 100 }
      ).then((data) => {
        if (data?.data) {
          setIsFetching(false);

          if (tab === 'upcoming') {
            setPastEvents([]);
            setUpcomingEvents(data.data);
          } else if (tab === 'past') {
            setUpcomingEvents([]);
            setPastEvents(data.data);
          }
          setMetadata({ offset: 10, limit: 10 });
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getEvents();
  }, [tab, types, switcher]);

  let calendarEvents = upcomingEvents.concat(pastEvents).map((item) => ({
    ...item,
    title: bdecode(item?.data?.title),
  }));

  return (
    <>
      <Container flex borderless className={cl.mainContainer}>
        <div>
          <div>
            <img src={liveEvents} alt="title" />

            <p>
              Join LIVE Listing Parties to learn from experts, connect with
              fellow sellers, and more!
              <br />
              <span style={{ fontWeight: 600 }}> Want to host a party ?</span>
            </p>
          </div>
        </div>

        <div className={cl.eventTypesContainer}>
          <div className={cl.leftButtonsContainer}>
            <a
              className={cl.requestHostButton}
              href="https://app.listperfectly.com/listing-party/host-signup/"
              target="_blank"
            >
              Request to Host
            </a>
            <a
              className={cl.hangoutButton}
              onClick={() => {
                dispatch(setShowModal(true));
                dispatch(
                  setShowLink(
                    'https://us06web.zoom.us/j/86271389078?pwd=bjhWOEhHQ1RpUnI2TnNLZDBhRCszZz09'
                  )
                );
              }}
            >
              Join 24hr Party Hangout
            </a>
          </div>

          <div className={cl.eventTypesWrapper}>
            <div className={cl.eventSelectWrapper}>
              {eventTypes?.map((item) => (
                <EventSelect
                  key={item.id}
                  title={item.title}
                  icon={item.icon}
                  setTab={setTab}
                  setTypes={setTypes}
                />
              ))}
            </div>
            <div className={cl.eventSwitcher}>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => handleChangeSwitcher('events')}
              >
                {typeLogic === 'events' ? (
                  <img src={activeEvents} width={40} alt="events" />
                ) : (
                  <img width={40} src={eventsSvg} alt="events" />
                )}
              </div>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => handleChangeSwitcher('calendar')}
              >
                {typeLogic === 'calendar' ? (
                  <img width={40} src={activeCalendar} calendar="calendar" />
                ) : (
                  <img width={40} src={calendar} alt="events" />
                )}
              </div>
            </div>
          </div>
        </div>

        {!isFetching ? (
          <div>
            {switcher === 'events' && tab === 'upcoming' ? (
              //  && upcomingEvents && upcomingEvents.length
              <InfiniteScroll
                style={{ overflow: 'none' }}
                dataLength={upcomingEvents.length}
                next={fetchData}
                hasMore={hasMore}
                loader={
                  upcomingEvents.length !== 0 && (
                    <div className={cl.infiniteLoader}>
                      <SVGloader />
                      <span>Loading...</span>
                    </div>
                  )
                }
              >
                {upcomingEvents.length > 0 ? (
                  upcomingEvents?.map((one, index) => {
                    const { data, date, hosts, id, pinned } = one;
                    const {
                      title,
                      description,
                      link,
                      host,
                      thumbnail,
                      dynamic_thumbnail,
                    } = data;
                    const currentDate = Date.now();
                    const eventDate = new Date(date).getTime();
                    const live =
                      eventDate - currentDate <= 600000 &&
                      currentDate - eventDate <= 7.2e6
                        ? true
                        : false;
                    return (
                      <EventCard
                        key={title + index}
                        image={thumbnail}
                        dynamicThumbnail={dynamic_thumbnail}
                        cardType="upcomingEvent"
                        {...{
                          date,
                          id,
                          pinned,
                          title,
                          tab,
                          description,
                          host,
                          hosts,
                          link,
                          live,
                        }}
                      />
                    );
                  })
                ) : (
                  <h1>No events</h1>
                )}
              </InfiniteScroll>
            ) : null}
            {switcher === 'events' && tab === 'past' ? (
              <InfiniteScroll
                dataLength={pastEvents.length}
                next={fetchData}
                hasMore={hasMore}
                loader={
                  pastEvents.length !== 0 && (
                    <div className={cl.infiniteLoader}>
                      <SVGloader />
                      <span>Loading...</span>
                    </div>
                  )
                }
              >
                {pastEvents.length > 0 ? (
                  pastEvents?.map((one, index) => {
                    const { data, date, hosts, id } = one;
                    const {
                      title,
                      description,
                      host,
                      thumbnail,
                      recording_links,
                    } = data;
                    return (
                      <EventCard
                        key={title + index}
                        image={thumbnail}
                        cardType="pastEvent"
                        {...{
                          date,
                          id,
                          title,
                          tab,
                          description,
                          host,
                          hosts,
                          recording_links,
                        }}
                      />
                    );
                  })
                ) : (
                  <h1>No events</h1>
                )}
              </InfiniteScroll>
            ) : null}
            <TermsModal />
          </div>
        ) : (
          <div className={cl.loader}>
            <SVGloader />
            <span>Loading...</span>
          </div>
        )}

        {switcher === 'calendar' && !isFetching && (
          <div>
            <MyCalendar
              calendarEvents={calendarEvents}
              setCalendarItems={setCalendarItems}
            />
          </div>
        )}

        {switcher === 'calendar' && calendarItems !== null && (
          <EventModal
            calendarItems={calendarItems}
            setCalendarItems={setCalendarItems}
            tab={tab}
          />
        )}
      </Container>
    </>
  );
};

export default EventsPage;
