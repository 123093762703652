import React, { useEffect, useRef, useState } from 'react';

import styles from './GroupMembersModal.module.scss';

import ModalButton from '../../../../../../components/ui/ModalButton/ModalButton';
import {
  SVGClose,
  SVGplus,
  SVGloader,
} from '../../../../../../assets/PackageSVG';

import profilePic from '../../../../../../assets/picture/profile.jpg';
import InviteMembersModal from '../InviteMembersModal/InviteMembersModal';

import useUsers from '../../../../../../hooks/useUsers';
import { useParams } from 'react-router-dom';
import GroupMemberItem from './components/GroupMemberItem/GroupMemberItem';
import { useGlobalContext } from '../../../../../../contexts/Context';
import { useSelector } from 'react-redux';
import { isRoleAllowedSelector } from '../../../../../../redux/selectors';

function GroupMembersModal(props) {
  const { handleToggleGroupMembersModal, userAdata } = props;

  const { user_id } = useParams();

  const { usersSelf } = useUsers();

  const { state } = useGlobalContext();

  const { chatItems } = state;

  const isRoleAllowed = useSelector(isRoleAllowedSelector);

  const [groupMembers, setGroupMembers] = useState([]);

  const [filterBy, setFilterBy] = useState({
    chat_id: user_id,
  });

  const [metadata, setMetadata] = useState({ offset: 0, limit: 10 });

  const [options] = useState({
    include_name: true,
    include_username: true,
  });

  const [isLoading, setIsLoading] = useState(true);

  const [userInfo, setUserInfo] = useState(false);

  const { groupUsersList } = useUsers();

  const groupMembersModalRef = useRef(null);

  const [isOpenInviteMembersModal, setIsOpenInviteMembersModal] =
    useState(false);

  const handleToggleInviteMembersModal = () => {
    setIsOpenInviteMembersModal(!isOpenInviteMembersModal);
  };

  useEffect(() => {
    getGroupMembers();
  }, []);

  const getGroupMembers = async () => {
    try {
      const currentChat = chatItems?.find((item) => item.id === user_id);
      const currentChatUsersLength =
        currentChat && currentChat?.users && currentChat?.users?.length;

      const groupMetadata = {
        ...metadata,
        limit: currentChatUsersLength || metadata.limit,
      };
      const response = await groupUsersList(filterBy, groupMetadata, options);
      if (response.data) {
        setGroupMembers(response.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const getUsersSelf = async () => {
      try {
        const response = await usersSelf();

        if (response.data) {
          setUserInfo(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getUsersSelf();
  }, []);

  return (
    <>
      <div className={styles.groupMembers__overlay}>
        <div
          ref={groupMembersModalRef}
          style={{ height: groupMembers?.length > 10 ? '599px' : 'auto' }}
          className={styles.modal}
        >
          <div
            className={styles.closeModalBtn}
            onClick={handleToggleGroupMembersModal}
          >
            <SVGClose />
          </div>
          <div className={styles.groupMembersHeader}>
            <h1>Group Members</h1>
            {isRoleAllowed && (
              <ModalButton feature="light" cb={handleToggleInviteMembersModal}>
                <span>
                  <SVGplus />
                </span>
                <span>Add Member</span>
              </ModalButton>
            )}
          </div>

          <div className={styles.membersWrapper}>
            {!isLoading ? (
              groupMembers?.map((item) => (
                <GroupMemberItem
                  key={item.id}
                  name={item.name}
                  username={item.username}
                  chat_id={item.chat_id}
                  user_id={item.user_id}
                  groupMembers={groupMembers}
                  setGroupMembers={setGroupMembers}
                  userInfo={userInfo}
                />
              ))
            ) : (
              <div className={styles.loader}>
                <SVGloader />
                <span>Loading...</span>
              </div>
            )}
          </div>
        </div>
      </div>

      {isOpenInviteMembersModal && (
        <InviteMembersModal
          handleToggleInviteMembersModal={handleToggleInviteMembersModal}
          getGroupMembers={getGroupMembers}
          userAdata={userAdata}
          participants={groupMembers}
        />
      )}
    </>
  );
}

export default GroupMembersModal;
