import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: '',
  isAuthorized: false,
  isLoading: true,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.token = action.payload.token;
      state.isAuthorized = action.payload.isAuthorized;
      // state.isLoading = action.payload.isLoadings
    },
    setIsLoading: (state, action) => {
      state.isLoading = false;
    },
  },
});

export const { setAuth, setIsLoading } = authSlice.actions;
export default authSlice.reducer;
