import useBasicFuncs from '../../hooks/useBasicFuncs';

import {
  SVGlocationcheck,
  SVGstar,
  SVGpencircle,
  SVGshop,
  SVGwandmagic,
  SVGbriefcase,
  SVGmegaphone,
} from '../../assets/PackageSVG';
import ColoredIcon from './ColoredIcon';
import Container from '../../components/Container/Container';

import cl from './ProfilePage.module.scss';

const TypeProfile = ({ akey: TypeName, adata, isFetching, hidden }) => {
  const { bdecode, getMpsImage } = useBasicFuncs();

  //
  //
  //

  // types_metadata > ['seller', 'assistant', 'consigner'];
  // const adata = data?.data?.types_metadata?.assistant || {};

  const notes = adata?.notes;
  const experience_level = adata?.experience_level;
  const preferred_listing_sites = adata?.preferred_listing_sites || [];
  const location_preferences = adata?.location_preferences || [];

  const listing_specialties = adata?.listing_specialties || []; // [{}]
  const services = adata?.services || [];
  const pricing = adata?.pricing || []; // [{}]

  const titleColor =
    ['orange', 'blue', 'purple']?.[
      ['seller', 'assistant', 'consigner'].indexOf(TypeName)
    ] || 'grey';

  //
  //
  //

  return (
    <Container
      className={cl.newwrapper}
      title={`${TypeName || 'The'} Profile`}
      {...{ titleColor, isFetching, hidden }}
    >
      {experience_level || location_preferences.length > 0 ? (
        <div className={cl.halfline}>
          {experience_level ? (
            <div className={cl.half}>
              <ColoredIcon color="orange">
                <SVGstar fill="orange"/>
              </ColoredIcon>

              <h4>Experience Level</h4>

              <ColoredIcon color="orange" className={cl.coloredSpan}>
                <span>{experience_level}</span>
              </ColoredIcon>
            </div>
          ) : null}

          {location_preferences.length > 0 ? (
            <div className={cl.half}>
              <ColoredIcon color="purple">
                <SVGlocationcheck fill="purple"/>
              </ColoredIcon>

              <h4>Location Preference</h4>

              {location_preferences?.map((loc_pref) => (
                <ColoredIcon
                  key={loc_pref}
                  color="purple"
                  className={cl.coloredSpan}
                >
                  <span>{loc_pref}</span>
                </ColoredIcon>
              ))}
            </div>
          ) : null}

          <hr></hr>
        </div>
      ) : null}

      {preferred_listing_sites?.length > 0 ? (
        <div className={cl.brick}>
          <div className={cl.line}>
            <ColoredIcon color="pink">
              <SVGshop fill='pink' />
            </ColoredIcon>
            <h4>Preferred Marketplaces</h4>
          </div>

          <div className={cl.imagesPool}>
            {preferred_listing_sites?.map((website, index) =>
              !website ? null : (
                <div
                  title={website}
                  className={cl.imageOne}
                  key={website + index}
                >
                  <img src={getMpsImage(website)} alt={`mp ${website}`}></img>
                  <span>{website}</span>
                </div>
              ),
            )}
          </div>

          <hr></hr>
        </div>
      ) : null}

      {notes ? (
        <div className={cl.brick}>
          <div className={cl.line}>
            <ColoredIcon color="yellow">
              <SVGpencircle fill='yellow'/>
            </ColoredIcon>
            <h4>{TypeName || 'The'} Notes</h4>
          </div>

          <pre>{bdecode(notes)}</pre>

          <hr></hr>
        </div>
      ) : null}

      {listing_specialties?.length > 0 ? (
        <div className={cl.brick}>
          <div className={cl.line}>
            <ColoredIcon color="blue">
              <SVGwandmagic fill='blue'/>
            </ColoredIcon>
            <h4>Listing Specialties</h4>
          </div>

          <div className={cl.keyvaluePool}>
            {listing_specialties?.map(({ name, descriptors }) => {
              if (!name) return null;
              if (descriptors?.length === 0) descriptors = [false];

              return (
                <div className={cl.kvwrap} key={name + descriptors.join('_')}>
                  <span className={cl.kvkey}>{bdecode(name)}</span>
                  <div className={cl.kvPool}>
                    {descriptors?.map((descriptor) => (
                      <ColoredIcon
                        key={(descriptor || 'skip') + name}
                        color={descriptor ? 'blue' : 'grey'}
                        className={cl.kvvalue}
                      >
                        <span>
                          {descriptor ? bdecode(descriptor) : 'Not specified'}
                        </span>
                      </ColoredIcon>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>

          <hr></hr>
        </div>
      ) : null}

      {services?.length > 0 ? (
        <div className={cl.brick}>
          <div className={cl.line}>
            <ColoredIcon color="purple">
              <SVGmegaphone fill='purple' />
            </ColoredIcon>
            <h4>Services</h4>
          </div>

          <div className={cl.keyvaluePool}>
            {services?.map((name, index) => (
              <div
                className={cl.kvwrap}
                key={'serv_' + name + index}
                style={{ width: 'auto' }}
              >
                <span className={cl.kvkey}>{bdecode(name)}</span>
                {/* <div className={cl.kvPool}>
									<ColoredIcon color={txt === 'Not specified' ? 'grey' : 'green'} className={cl.kvvalue}>
										<span>{bdecode(txt)}</span>
									</ColoredIcon>
								</div> */}
              </div>
            ))}
          </div>

          <hr></hr>
        </div>
      ) : null}

      {pricing?.length > 0 ? (
        <div className={cl.brick}>
          <div className={cl.line}>
            <ColoredIcon color="green">
              <SVGbriefcase fill='green' />
            </ColoredIcon>
            <h4>Pricing</h4>
          </div>

          <div className={cl.keyvaluePool}>
            {pricing?.map(({ name, type, value }, index) => {
              let txt = '';
              txt = `${type === 'percentage' ? '' : '$'}${value}${
                type === 'percentage' ? '%' : ' ' + type.split('_').join(' ')
              }`;
              if (!value || value === 0) txt = 'Not specified';

              return (
                <div
                  className={cl.kvwrap}
                  key={name + type + value + index}
                  style={{ width: 'auto' }}
                >
                  <span className={cl.kvkey}>{bdecode(name)}</span>
                  <div className={cl.kvPool}>
                    <ColoredIcon
                      color={txt === 'Not specified' ? 'grey' : 'green'}
                      className={cl.kvvalue}
                    >
                      <span>{bdecode(txt)}</span>
                    </ColoredIcon>
                  </div>
                </div>
              );
            })}
          </div>

          <hr></hr>
        </div>
      ) : null}
    </Container>
  );
};

export default TypeProfile;
