import React, { useEffect, useRef } from 'react';

import styles from './LeaveGroupModal.module.scss';

import ModalButton from '../../../../../../components/ui/ModalButton/ModalButton';

function LeaveGroupModal(props) {
  const { handleToggleLeaveGroupModal } = props;

  const leaveGroupRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        leaveGroupRef.current &&
        !leaveGroupRef.current.contains(event.target)
      ) {
        handleToggleLeaveGroupModal();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [leaveGroupRef]);

  return (
    <div className={styles.leaveGroupModal__overlay}>
      <div ref={leaveGroupRef} className={styles.modal}>
        <h1>Are you sure you want to leave this group?</h1>
        <p>
          This action cannot be undone. Once you leave, you will have to be
          invited by a current member to join again.{' '}
        </p>
        <div className={styles.modal__btns}>
          <ModalButton feature="light" cb={handleToggleLeaveGroupModal}>
            No, exit
          </ModalButton>
          <ModalButton
            feature="primary"
            // cb={handleEditMessage}
          >
            Yes, Leave Group
          </ModalButton>
        </div>
      </div>
    </div>
  );
}

export default LeaveGroupModal;
