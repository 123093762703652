import React from 'react';
import Dropdown from '../../../components/ui/dropdowns/Dropdown';
import cl from './FormExperience.module.scss';

const FormExperience = ({
  setSaveBtnDisabled,
  experience,
  setExperience,
  title,
}) => {
  const options = [
    { value: 'Less than 1 year', label: 'Less than 1 year', valueKey: '1' },
    { value: '1-2 years', label: '1-2 years', valueKey: '2' },
    { value: '3-5 years', label: '3-5 years', valueKey: '3' },
    { value: '6-14 years', label: '6-15 years', valueKey: '4' },
    { value: '15+ years', label: '15+ years', valueKey: '5' },
  ];

  const handleChange = (value) => {
    let type = title.replace(/a /, '').replace(/an /, '').toLowerCase();
    setSaveBtnDisabled((prev) => ({ ...prev, [type]: false }));
    if (!value) {
      setExperience(' ');
    } else {
      setExperience(value.value);
    }
  };

  const ddValue = { value: experience, label: experience };

  return (
    <>
      <div className={cl.heading}>
        <div style={{ display: 'flex' }}>
          <h2>How long have you been a {title}?</h2>
        </div>
        <p>Select one.</p>
      </div>
      <Dropdown
        {...{ options, handleChange }}
        value={ddValue}
        isClearable={true}
        isSearchable={true}
        title={title.toLowerCase()}
      />
    </>
  );
};

export default FormExperience;
