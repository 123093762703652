import React, { useEffect, useState } from 'react';

import styles from '../../GroupMembersModal.module.scss';

import profilePic from '../../../../../../../../assets/picture/profile.jpg';

import { SVGUserMinus } from '../../../../../../../../assets/PackageSVG';
import useUsers from '../../../../../../../../hooks/useUsers';
import { useGlobalContext } from '../../../../../../../../contexts/Context';
import { useSelector } from 'react-redux';
import { isRoleAllowedSelector } from '../../../../../../../../redux/selectors';

function GroupMemberItem(props) {
  const {
    name,
    username,
    chat_id,
    user_id,
    groupMembers,
    setGroupMembers,
    userInfo,
  } = props;

  const { first_name } = name;

  const { groupUsersDelete } = useUsers();

  const { state, dispatch } = useGlobalContext();

  const {
    chatItems,
    //  isRoleAllowed
  } = state;

  const isRoleAllowed = useSelector(isRoleAllowedSelector);

  const removeUserInGroup = async () => {
    try {
      const response = await groupUsersDelete(user_id, chat_id);

      if (response.data) {
        const tempGroupMembers = groupMembers.filter(
          (item) => item.user_id !== user_id
        );

        if (chatItems) {
          const updatedChatItems = chatItems.map((item) => {
            if (item && chat_id) {
              if (item.id === chat_id) {
                return {
                  ...item,
                  users: item?.users?.filter((fItem) => fItem.id !== user_id),
                };
              }

              return item;
            }
          });

          dispatch({
            type: 'CHATITEMS',
            payload: updatedChatItems,
          });
        }

        setGroupMembers(tempGroupMembers);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.membersWrapperItem}>
      <div className={styles.left}>
        <div className={styles.wrapperItemPhoto}>
          <img src={profilePic} alt="" />
        </div>
        <div className={styles.wrapperItemInfo}>
          <span>{first_name}</span>
          <span>@{username}</span>
        </div>
      </div>
      {userInfo.id !== user_id && isRoleAllowed && (
        <div className={styles.right} onClick={removeUserInGroup}>
          <SVGUserMinus />
        </div>
      )}
    </div>
  );
}

export default GroupMemberItem;
