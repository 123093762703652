import React, { useState, useEffect } from 'react';

import DatePicker from 'react-datepicker';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';

import useUsers from '../../hooks/useUsers';
import useBasicFuncs from '../../hooks/useBasicFuncs';

import { SVGremoveIcon, SVGclose } from '../../assets/PackageSVG';
import LPButton from '../../components/ui/button/LPButton';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import cl from './ProgressModal.module.scss';

const ProgressModal = ({ setShowProgressModal, goalId, setProgressStringState, setProgressPercentageState }) => {
	//for request
	const [filter_by, setFilter_by] = useState({ id: goalId });

	//inputs
	const [progressQuantity, setProgressQuantity] = useState('');
	const [startDate, setStartDate] = useState(new Date());

	//rendered txt
	const [title, setTitle] = useState('test title');
	const [progressHistory, setProgressHistory] = useState([]);
	const [created_at, setCreated_at] = useState();
	const [updated_at, setUpdated_at] = useState();

	//progress circle
	const [total, setTotal] = useState();
	const [progressString, setProgressString] = useState('');
	const [progressPercentage, setProgressPercentage] = useState('');

	const { goalsList, goalsUpdate } = useUsers();
	const { bdecode } = useBasicFuncs();

	const { isFetching, error, data } = useQuery(['goalsProgress'], () => goalsList(filter_by, { offset: 0, limit: 1 }));

	useEffect(() => {
		if (data?.data) {
			const goalData = data?.data[0];
			const progress = goalData?.data?.progress || [];

			setProgressHistory(progress);
			setTitle(goalData?.data?.goal || '');
			setTotal(goalData?.data?.total_quantity || 0);
			setCreated_at(goalData?.created_at);
			setUpdated_at(goalData?.data?.updated_at);

			let progressString = getCurrentProgress('string', progress, goalData?.data?.total_quantity);
			setProgressString(progressString);
			let progressPercentage = getCurrentProgress('percentage', progress, goalData?.data?.total_quantity);
			setProgressPercentage(progressPercentage);
		}
	}, [data]);

	//add error handler

	const handleAddProgress = () => {
		if (!progressQuantity || progressQuantity < 0) {
			notifyError('Progress must be more than 0. Cannot save progress.');
			return;
		}
		progressHistory.push({ quantity: parseInt(progressQuantity), date: startDate });
		const filter_by = { id: goalId };
		goalsUpdate(filter_by, { data: { progress: progressHistory } }).then(({ data }) => {
			notifySuccess('Progress added.');
			const progress = data?.data?.progress || [];
			let progressString = getCurrentProgress('string', progress, total);
			setProgressString(progressString);
			let progressPercentage = getCurrentProgress('percentage', progress, total);
			setProgressPercentage(progressPercentage);
			setProgressPercentageState(progressPercentage);
			setProgressStringState(progressString);
			setProgressHistory(progress);
			setProgressQuantity('');
			(error) => {
				console.error('Error saving progress.', error);
				notifyError('Error saving progress.');
			};
		});
	};

	const handleRemoveProgress = (progressId) => {
		let updProgHistory = progressHistory.filter((one, ind) => {
			return ind !== progressId;
		});

		const filter_by = { id: goalId };
		goalsUpdate(filter_by, { data: { progress: updProgHistory } }).then(({ data }) => {
			notifySuccess('Progress removed.');
			const progress = data?.data?.progress || [];
			let progressString = getCurrentProgress('string', progress, total);
			setProgressString(progressString);
			let progressPercentage = getCurrentProgress('percentage', progress, total);
			setProgressPercentage(progressPercentage);
			setProgressPercentageState(progressPercentage);
			setProgressStringState(progressString);
			setProgressHistory(progress);
			setProgressQuantity('');
			(error) => {
				console.error('Error removing progress.', error);
				notifyError('Error removing progress.');
			};
		});
	};

	const getCurrentProgress = (type, progressArray, total) => {
		if (progressArray && progressArray.length > 0) {
			const sum = progressArray.map(({ quantity }) => {
				return quantity;
			}).reduce((accumulator, one) => {
				return accumulator + one;
			});
			if (type === 'string') return sum.toString();
			if (type === 'percentage') {
				let percentage = (sum * 100) / total;
				if (percentage > 100) return 100;
				else return percentage;
			}
		} else {
			if (type === 'string') return '0';
			else if (type === 'percentage') return 1;
		}
	};

	//
	//
	//toasts
	const notifySuccess = (msg) => {
		toast(msg, { position: 'bottom-right', autoClose: 5000, pauseOnFocus: false, pauseOnHover: false });
	};
	const notifyError = (msg = 'Error occured.') => {
		toast.warn(msg, { position: 'bottom-right', autoClose: 15000, hideProgressBar: true });
	};
	return (
		<>
			<div
				className={cl.overlay}
				onClick={(e) => {
					e.stopPropagation();
					if (e.target === e.currentTarget) {
						setShowProgressModal(false);
					}
				}}>
				<div className={cl.centered}>
					<div className={cl.modal}>
						<div className={cl.modalHeader}>
							<span className={cl.heading}>Add Progress</span>
							<SVGclose 
								width={16}
								height={16}
								fill='#ffffff'
								onClick={() => {
									setShowProgressModal(false);
								}}
							/>
						</div>
						<div className={cl.body}>
							<div className={cl.title}>
								<h2>{bdecode(title)}</h2>
							</div>

							<div className={cl.dates}>
								<div>
									<span>Created:</span>
									<span>{moment(created_at).format('L')}</span>
								</div>
								<div>
									<span>Last updated:</span>
									<span>{moment(updated_at).format('L')}</span>
								</div>
							</div>

							<div className={cl.inputs}>
								<DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
								<input type='number' max={total} placeholder='Qty' value={progressQuantity} onChange={({ target }) => setProgressQuantity(target.value)}></input>
								<LPButton line className={cl.saveBtn} onClick={handleAddProgress}>
									Save
								</LPButton>
							</div>

							<div className={cl.progressHistory}>
								<div className={cl.left}>
									<h2>Progress History:</h2>
									<div className={cl.circleWrap}>
										<div className={cl.circle}>
											<div className={[cl.mask, cl.full].join(' ')} style={{ transform: `rotate(${progressPercentage * 1.8}deg)` }}>
												<div className={cl.fill} style={{ transform: `rotate(${progressPercentage * 1.8}deg)` }}></div>
											</div>
											<div className={[cl.mask, cl.half].join(' ')}>
												<div className={cl.fill} style={{ transform: `rotate(${progressPercentage * 1.8}deg)` }}></div>
											</div>
											<div className={cl.insideCircle}>
												{' '}
												<div>
													<p>
														{progressString} / {total}{' '}
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className={cl.right}>
									<div className={[cl.line, cl.top].join(' ')}>
										<span>Date:</span>
										<span>Quantity:</span>
									</div>

									{progressHistory.length > 0 &&
										progressHistory.map(({ quantity, date }, ind) => {
											let randNum = Math.random();
											return (
												<div className={cl.line} key={quantity + date + randNum}>
													<span>{moment(date).format('L')}</span>
													<span className={cl.qty}>{quantity}</span>
													<SVGremoveIcon onClick={() => handleRemoveProgress(ind)} />
												</div>
											);
										})}
								</div>
							</div>

							<div className={cl.modalActions}>
								<LPButton
									line
									onClick={() => {
										if (progressQuantity) {
											handleAddProgress();
										}
										setShowProgressModal(false);
									}}>
									Save and Exit
								</LPButton>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ProgressModal;