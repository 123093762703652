import React from 'react';
import { useState, useEffect } from 'react';
import useBasicFuncs from '../../hooks/useBasicFuncs';
import { useNavigate } from 'react-router-dom';
import {
  SVGexperience,
  SVGonSite,
  SVGvirtual,
  SVGmoreMps,
} from '../../assets/PackageSVG';
import LPButton from '../../components/ui/button/LPButton';
import Image from '../../components/ui/image/Image';

import cl from './Card.module.scss';
import Badge from '../../components/Badge/Badge';
import useUsers from '../../hooks/useUsers';
import DirectoryBadge from '../../components/Badge/DirectoryBadge';
import { useSelector } from 'react-redux';
import { mediatorSelector } from '../../redux/selectors';

const Card = ({
  name,
  username,
  location,
  photo,
  type,
  typeData,
  id,
  lastListingRef,
  badges,
}) => {
  const { first_name, last_name } = name;
  const { city, state, country, zip } = location;
  const {
    notes,
    experience_level,
    listing_specialties,
    location_preferences,
    preferred_listing_sites,
    services,
  } = typeData;

  const listingSpecs = listing_specialties?.map(({ name }) => name);
  const profile_url =
    photo?.key && 'https://s3.amazonaws.com/cdn.listingparty.com/' + photo?.key;
  const specialStyleClass = cl?.[`${type}`];

  //
  //
  //

  const navigate = useNavigate();
  const { bdecode, getMpsImage } = useBasicFuncs();

  let arr = [];
  for (let i = 0; i < badges?.length; i++) {
    arr.push(badges[i].code);
  }
  return (
    <div className={cl.wrapper} ref={lastListingRef}>
      <div className={cl.block}>
        <div className={cl.line}>
          <div className={[cl.header, specialStyleClass].join(' ')}>
            <div className={cl.innerDiv}>
              <div className={cl.picture}>
                <Image src={profile_url} alt="profile"></Image>
              </div>
              {badges == null ? (
                <div></div>
              ) : (
                <DirectoryBadge
                  count={arr?.length}
                  user={first_name || ''}
                  badges={arr}
                />
              )}

              <div className={cl.innerRow}>
                <div className={cl.topLine}>
                  {!name?.public ? null : (
                    <div
                      className={cl.name}
                      title={(first_name || '') + ' ' + (last_name || '')}
                    >
                      <h2>{bdecode(first_name)}</h2>
                      <h2>{bdecode(last_name)}</h2>
                    </div>
                  )}

                  <span title={username}>@{username}</span>
                </div>

                <div className={cl.botLine}>
                  <div className={[cl.typeTxt, specialStyleClass].join(' ')}>
                    <span>{type}</span>
                  </div>
                  {!location?.public ? null : (
                    <span>
                      {city}, {state}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            !experience_level && !location_preferences ? [cl.hidden] : cl.line
          }
        >
          {experience_level && (
            <div className={cl.leftLine}>
              <SVGexperience />
              {experience_level}
            </div>
          )}

          <div className={cl.rightLine}>
            {location_preferences?.length > 0 ? (
              <div className={cl.tagsPool}>
                {location_preferences?.map((pref, index) => (
                  <div key={pref + index + username}>
                    {pref === 'virtual' ? <SVGvirtual /> : <SVGonSite />}
                    <span>{pref}</span>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>

        <div className={cl.line}>
          <div className={cl.mpsDiv}>
            <div className={cl.mpsPool}>
              {preferred_listing_sites?.length > 0 ? (
                preferred_listing_sites?.map((site, index) => (
                  <div title={site} className={cl.mpImg} key={site + index}>
                    {index === 9 ? (
                      <SVGmoreMps
                        className={cl.moreMpsIcon}
                        key={index + site}
                      />
                    ) : index < 9 ? (
                      <img src={getMpsImage(site)} alt={`mp ${site}`}></img>
                    ) : null}
                  </div>
                ))
              ) : (
                <p>No Marketplaces mentioned</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={cl.block}>
        <div className={cl.line}>
          <div className={cl.innerNotesDiv}>
            <div className={cl.topLine}>
              {notes ? (
                <>
                  <p>{bdecode(notes)}</p>
                  <span onClick={() => navigate(`/profile/${id}`)}>
                    Read More
                  </span>
                </>
              ) : null}
            </div>

            <div className={cl.botLine}>
              <LPButton onClick={() => navigate(`/profile/${id}`)} line>
                View Profile
              </LPButton>
            </div>
          </div>
        </div>
      </div>

      <div className={cl.block}>
        <div className={type !== 'seller' ? cl.line : cl.hidden}>
          <div className={cl.servicesDiv}>
            <h4>Services</h4>
            <div className={cl.tagsPoolServ}>
              {services?.length > 0 ? (
                services?.map((serv, index) => {
                  if (!serv || index > 1) return null;
                  return (
                    <span key={serv + index + username}>
                      {index === 0
                        ? bdecode(serv)
                        : '+' + (services?.length - 1)}
                    </span>
                  );
                })
              ) : (
                <p>No Services mentioned</p>
              )}
            </div>
          </div>
        </div>
        <div className={cl.line}>
          <div className={cl.specialtiesDiv}>
            <h4>Specialties</h4>
            <div className={cl.tagsPoolSpecs}>
              {listingSpecs?.length > 0 ? (
                listingSpecs.map((spec, index) => {
                  if (!spec || index > 1) return null;
                  return (
                    <span key={spec + index + username}>
                      {index === 0
                        ? bdecode(spec)
                        : '+' + (listingSpecs?.length - 1)}
                    </span>
                  );
                })
              ) : (
                <p>No Specialties mentioned</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
