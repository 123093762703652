import { useContext, useEffect, useCallback } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import history from './useHistory';

const useBlocker = (blocker, when = true) => {
  const { navigator } = useContext(NavigationContext);
  // console.log(navigator);
  useEffect(() => {
    if (!when) return;

    const unblock = history.block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
};
const usePrompt = (message, when = true) => {
  const blocker = useCallback(
    (tx) => {
      // change to custom once we have it
      if (window.confirm(message)) tx.retry();
    },
    [message],
  );

  useBlocker(blocker, when);
};

export default usePrompt;
