import React from 'react';
import { useSelector } from 'react-redux';

import { baseAwsUrlSelector } from '../../redux/selectors';
import Listing from './Listing';

const ListingsList = ({ listingsData, lastListingRef }) => {
  const baseAwsUrl = useSelector(baseAwsUrlSelector);

  return (
    listingsData.length > 0 &&
    listingsData.map(({ user, metadata }, index) => {
      let { username, id: userId, photo: userPhoto } = user;
      userPhoto = userPhoto ? baseAwsUrl + '/' + user.photo.key : '';
      const title = metadata[133];
      const region = metadata[727]?.region;
      const bucket = metadata[727]?.bucket;
      const key = metadata[727]?.key;
      const image =
        bucket && key
          ? `https://s3${
              region ? '.' + region : ''
            }.amazonaws.com/${bucket}/${key}`
          : null;
      const placeholderSrc =
        bucket && key
          ? `https://s3${
              region ? '.' + region : ''
            }.amazonaws.com/${bucket}/${key.replace('_CI.', '_TN.')}`
          : null;
      const price = metadata[22];
      const listingStatus = metadata[539.1];
      const shops = [];
      for (let i = 2; i <= 14; i++) {
        if (i === 7 || i === 12) continue;

        const shopsArr = [
          '',
          '',
          '618',
          '619',
          '620',
          '621',
          '833',
          '',
          '839',
          '840',
          '',
          '841',
          '853',
          '',
          '856',
        ];
        const shopNames = [
          '',
          '',
          'ebay',
          'etsy',
          'poshmark',
          'mercari',
          'tradesy',
          '',
          'grailed',
          'facebook',
          '',
          'depop',
          'kidizen',
          '',
          'instagram',
        ];
        const shopLinks = [
          '',
          '',
          'https://www.ebay.com/itm/',
          'https://www.etsy.com/listing/',
          'https://poshmark.com/listing/',
          'https://www.mercari.com/us/item/',
          'https://www.tradesy.com/i//',
          '',
          'https://www.grailed.com/listings/',
          'https://www.facebook.com/marketplace/item/',
          '',
          'https://www.depop.com/products/',
          'https://www.kidizen.com/items/',
          '',
          'https://www.instagram.com/p/',
        ];
        const shopId = shopsArr[i];
        const status = `539.${i}`;

        if (metadata[status] && metadata[shopId]) {
          let name = shopNames[i];
          let prefix = shopLinks[i];
          let obj = {
            name: name,
            status: metadata[status],
            link: `${prefix}${metadata[shopId]}`,
          };
          shops.push(obj);
        }
      }

      const shopsToShow = shops.map(({ name, status, link }) => {
        if (status) {
          return { shop: name, link: link };
        }
        return null;
      });

      const props = {
        image: image,
        title: title,
        price: price,
        shopsToShow: shopsToShow,
        username: username,
        userPhoto: userPhoto,
        userId: userId,
        listingStatus: listingStatus,
        placeholderSrc: placeholderSrc,
      };
      if (listingsData.length === index + 1) {
        return (
          <Listing
            key={userId + index}
            lastListingRef={lastListingRef}
            props={props}
          ></Listing>
        );
      } else {
        return <Listing key={userId + index} props={props}></Listing>;
      }
    })
  );
};

export default ListingsList;
