import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
import moment from 'moment';
import {
  SVGcalendardays,
  SVGchevrondown,
  SVGhourglassclock,
  SVGcopy,
  SVGcirclecheck,
  SVGcircletrash,
  SVGchartlineup,
  SVGpentosquare,
  SVGusers,
} from '../../assets/PackageSVG';

import useUsers from '../../hooks/useUsers';
import useBasicFuncs from '../../hooks/useBasicFuncs';

import LPButton from '../../components/ui/button/LPButton';
import ProgressModal from './ProgressModal';
import DueDateDropdown from './DueDateDropdown';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import cl from './Goal.module.scss';

const Goal = ({
  one,
  data,
  tab,
  setGoals,
  goals,
  setCopyGoal,
  lastGoalRef,
}) => {
  const { due_date, id, completion_date } = one;
  const { goal: title, total_quantity, type: category, progress, notes } = data;
  const getCurrentProgress = (type, progressArray = []) => {
    if (progressArray.length > 0) {
      const sum = progressArray
        .map(({ quantity }) => {
          return quantity;
        })
        .reduce((accumulator, one) => {
          return accumulator + one;
        });
      if (type === 'string') return sum.toString();
      if (type === 'percentage') {
        let percentage = (sum * 100) / total_quantity;
        if (percentage > 100) return 100;
        else return percentage;
      }
    } else {
      if (type === 'string') return '0';
      else if (type === 'percentage') return 1;
    }
  };
  let progressString = getCurrentProgress('string', progress);
  let progressPercentage = getCurrentProgress('percentage', progress);

  //goal data to render
  const [startDate, setStartDate] = useState(new Date(due_date));
  const [divCategory, setDivCategory] = useState(category);
  const [divTitle, setDivTitle] = useState(title);
  const [divDate, setDivDate] = useState(due_date);
  const [divTotal, setDivTotal] = useState(total_quantity);
  const [divNotes, setDivNotes] = useState(notes);

  const [showBtns, setShowBtns] = useState(false);
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [editEnabled, setEditEnabled] = useState(false);
  const [errorWithTotal, setErrorWithTotal] = useState(false);
  const [showNotesMsg, setShowNotesMsg] = useState(null);
  const [showTitleMsg, setShowTitleMsg] = useState(null);

  const [dueDateOption, setDueDateOption] = useState(null);

  const [progressStringState, setProgressStringState] =
    useState(progressString);
  const [progressPercentageState, setProgressPercentageState] =
    useState(progressPercentage);

  const { goalsUpdate } = useUsers();
  const { bdecode } = useBasicFuncs();

  //
  //
  //for overdue goals, calculate how mahy hours/days the goal is overdue
  const getOverdueDate = () => {
    if (tab !== 'overdue') return;
    const daysDiff = moment().diff(due_date, 'days');
    if (daysDiff < 1) {
      const hoursDiff = moment().diff(due_date, 'hours');
      return `${hoursDiff} hours overdue`;
    }
    return `${daysDiff} days overdue`;
  };
  const overdueDate = getOverdueDate();
  const correctDate =
    tab !== 'completed'
      ? moment(startDate).format('MMMM Do, YYYY')
      : moment(completion_date).format('MMMM Do, YYYY');

  //
  //
  //css slass when edit is enabled
  const activeClass = editEnabled ? cl.active : null;

  //
  //
  //actions
  const handleDelete = (goalId) => {
    if (window.confirm('Are you sure you want to delete your goal?')) {
      goalsUpdate({ id: goalId }, { status: 'deleted' }).then(() => {
        notifySuccess('Goal deleted!');
        let updGoals = goals.filter(({ id }) => {
          return id !== goalId;
        });
        setGoals(updGoals);
        (error) => {
          console.error('Error deleting goal.', error);
          notifyError('Error deleting goal.');
        };
      });
    } else return;
    setShowBtns(false);
  };

  const handleCopy = () => {
    if (
      window.confirm("We will open this goal in the 'Add Goal' section above!")
    ) {
      setCopyGoal({
        category: divCategory,
        title: divTitle,
        notes: divNotes,
        total: divTotal,
        due_date: startDate,
      });
    }
  };

  const handleMarkCompleted = (goalId) => {
    if (window.confirm('You are about to mark this goal completed!')) {
      goalsUpdate({ id: goalId }, { status: 'completed' }).then(
        ({ data }) => {
          let updGoals = goals.filter(({ id }) => {
            return id !== goalId;
          });
          notifySuccess(
            'Goal completed! Moving to completed tab once refreshed.',
          );
          setGoals([...updGoals, data]);
        },
        (error) => {
          console.error('Error marking the goal completed.', error);
          notifyError('Error marking the goal completed.');
        },
      );
    } else return;
    setShowBtns(false);
  };

  //
  //
  //

  const handleCancelEdit = () => {
    setShowNotesMsg(null);
    setShowTitleMsg(null);
    setErrorWithTotal(false);
    setEditEnabled(false);
    setShowBtns(false);
  };

  const handleSaveEdits = (goalId) => {
    if (errorWithTotal) {
      notifyError('Total should contain digits only');
      return;
    }
    setShowNotesMsg(null);
    setShowTitleMsg(null);

    const data = {
      type: bdecode(divCategory.toLowerCase().replace(' ', '_')),
      goal: bdecode(divTitle),
      total_quantity: parseInt(divTotal),
      notes: bdecode(divNotes),
    };

    const dueDateOptSelected = dueDateOption
      ? new Date(
          dueDateOption.value === 'Custom Due Date'
            ? startDate
            : dueDateOption.value,
        )
      : startDate;
    const due_date = { due_date: dueDateOptSelected };
    goalsUpdate({ id: goalId }, { data, ...due_date }).then(
      (data) => {
        setShowBtns(false);
        setEditEnabled(false);
        let newGoal = data.data;
        let updGoals = goals;
        if (
          moment().diff(newGoal?.due_date, 'minutes') > 0 &&
          tab !== 'overdue'
        ) {
          notifySuccess('Goal edited and moved to "Overdue" tab!');
          updGoals = updGoals.filter(({ id }) => {
            return id !== goalId;
          });
          setGoals([...updGoals]);
          return;
        } else if (
          moment().diff(newGoal?.due_date, 'minutes') < 0 &&
          tab === 'overdue'
        ) {
          notifySuccess('Goal edited and moved to "Upcoming" tab!');
          updGoals = updGoals.filter(({ id }) => {
            return id !== goalId;
          });
          setGoals([...updGoals]);
          return;
        }
        notifySuccess('Edits saved!');
        let oldGoal = updGoals.find(({ id }) => {
          return id === goalId;
        });
        Object.assign(oldGoal, newGoal);
        setStartDate(newGoal.due_date);
        setGoals([...updGoals]);
      },
      (error) => {
        console.error('Error. Edits not saved.', error);
        notifyError('Error. Edits not saved.');
      },
    );
  };

  //
  //
  //progress modal
  const handleOpenAddProgress = (goalId) => {
    setShowProgressModal(true);
    setShowBtns(false);
  };

  //
  //
  //toasts
  const notifySuccess = (msg) => {
    toast(msg, {
      position: 'bottom-right',
      autoClose: 5000,
      pauseOnFocus: false,
      pauseOnHover: false,
    });
  };
  const notifyError = (msg = 'Error occured.') => {
    toast.warn(msg, {
      position: 'bottom-right',
      autoClose: 15000,
      hideProgressBar: true,
    });
  };

  //
  //
  // css class for category color code
  const categoryColor =
    divCategory === 'listing_prep'
      ? 'violet'
      : divCategory === 'listing'
      ? 'peach'
      : divCategory === 'inventory_management'
      ? 'pink'
      : divCategory === 'sourcing'
      ? 'green'
      : divCategory === 'shipping'
      ? 'yellow'
      : divCategory === 'social'
      ? 'purple'
      : divCategory === 'business_growth'
      ? 'blue'
      : divCategory === 'personal' && 'aqua';
  const typeColor =
    tab === 'overdue'
      ? 'light_red'
      : tab === 'upcoming'
      ? 'blue'
      : tab === 'completed' && 'green';

  return (
    <>
      <div className={cl.card} ref={lastGoalRef}>
        <div className={[cl.header, cl[[typeColor]]].join(' ')}>
          <div>
            {divCategory
              ? bdecode(divCategory.replace('_', ' '))
              : 'No category selected'}
          </div>
        </div>
        <div className={cl.body}>
          <div className={cl.tags}>
            {/* <span>tag1</span>
						<span>tag2</span>
						<span>tag3</span> */}
          </div>
          <div className={cl.title}>
            <div
              className={[cl.title, activeClass].join(' ')}
              contentEditable={editEnabled}
              suppressContentEditableWarning={true}
              onBlur={(e) => setDivTitle(bdecode(e.target.innerText))}
              onKeyPress={(e) => {
                if (e.target.innerText.length >= 65) {
                  setShowTitleMsg('Max 65 characters.');
                  e.preventDefault();
                }
              }}
            >
              {bdecode(divTitle)}
            </div>
            {showTitleMsg ? <div className={cl.msg}>{showTitleMsg}</div> : null}
          </div>

          <div className={cl.notes}>
            <div
              className={[cl.notes, activeClass].join(' ')}
              contentEditable={editEnabled}
              suppressContentEditableWarning={true}
              onBlur={(e) => setDivNotes(bdecode(e.target.innerText))}
              onKeyPress={(e) => {
                if (e.target.innerText.length >= 80) {
                  setShowNotesMsg('Max 80 characters.');
                  e.preventDefault();
                }
              }}
              placeholder="notes"
            >
              {bdecode(notes) || 'no notes added'}
            </div>
            {showNotesMsg ? <div className={cl.msg}>{showNotesMsg}</div> : null}
          </div>

          <div className={cl.dateAndShare}>
          {tab !== 'overdue' ? (
              <SVGcalendardays fill="#363636" width={17} height={17} />
            ) : (
              <SVGhourglassclock width={17} height={17} />
            )}

            {!editEnabled ? (
              <div className={cl.date}>
                {tab !== 'overdue' ? correctDate : overdueDate}
              </div>
            ) : null}

            {/* <div>
							<SVGusers fill="#363636" width={17} height={17}/>
							<div>378</div>
						</div> */}

            {editEnabled ? (
              <div className={cl.datepicker}>
                <DueDateDropdown
                  popperClassName="datePicker"
                  dueDateOption={dueDateOption}
                  setDueDateOption={setDueDateOption}
                  startDate={startDate}
                  setStartDate={setStartDate}
                />
              </div>
            ) : null}
          </div>

          <div className={cl.progressBar}>
            <div className={cl.meter}>
              <span
                className={[
                  cl.bar,
                  progressPercentageState >= 100 && cl.completed,
                ].join(' ')}
                style={{ width: `${progressPercentageState}%` }}
              ></span>
            </div>
            <div className={cl.progressStats}>
              <div>{progressStringState}</div>/
              <div
                className={[
                  cl.total,
                  activeClass,
                  errorWithTotal && cl.error,
                ].join(' ')}
                contentEditable={editEnabled}
                suppressContentEditableWarning={true}
                onBlur={(e) => {
                  if (!/^\d+$/.test(e.target.innerText)) {
                    setErrorWithTotal(true);
                  } else {
                    setErrorWithTotal(false);
                    setDivTotal(e.target.innerText);
                  }
                }}
              >
                {divTotal}
              </div>
              {/* {errorWithTotal ? <div>Digits only in total</div> : null} */}
            </div>
          </div>
          {tab === 'completed' ? (
            <div className={cl.txt}>Your Goal is Completed!</div>
          ) : null}
          <div className={cl.actionsParent}>
            {showBtns ? (
              <div className={cl.actions}>
                {tab !== 'completed' ? (
                  <div onClick={() => setEditEnabled(true)}>
                    <SVGpentosquare width={17} height={17} />
                    Edit
                  </div>
                ) : null}
                {!editEnabled ? (
                  <div onClick={() => handleCopy(id)}>
                   <SVGcopy width={17} height={17} />
                    Copy
                  </div>
                ) : null}
                {tab !== 'completed' && !editEnabled ? (
                  <div onClick={() => handleOpenAddProgress(id)}>
                    <SVGchartlineup width={17} height={17} />
                    Add Progress
                  </div>
                ) : null}
                {tab !== 'completed' && !editEnabled ? (
                  <div onClick={() => handleMarkCompleted(id)}>
                    <SVGcirclecheck width={17} height={17} />
                    Mark Complete
                  </div>
                ) : null}
                {!editEnabled ? (
                  <div onClick={() => handleDelete(id)}>
                    <SVGcircletrash width={17} height={17} />
                    Delete
                  </div>
                ) : null}
                {editEnabled ? (
                  <>
                    <button
                      className={cl.saveBtn}
                      onClick={() => handleSaveEdits(id)}
                    >
                      Save
                    </button>
                    <button
                      className={cl.saveBtn}
                      onClick={() => handleCancelEdit()}
                    >
                      Cancel
                    </button>
                  </>
                ) : null}
              </div>
            ) : null}
          </div>

          {showBtns && !editEnabled ? (
            <div
              className={cl.clickToClose}
              onClick={() => {
                setShowBtns(false);
              }}
            ></div>
          ) : null}
          <div className={cl.btnsDropdown}>
            {!editEnabled ? (
              <LPButton
                line
                className={cl.editGoalBtn}
                onClick={() => setShowBtns((prev) => !prev)}
              >
                Edit Goal <SVGchevrondown width={17} height={17} style={{marginBottom: '-3px'}}/>
              </LPButton>
            ) : (
              <div className={cl.fakeBtn}></div>
            )}
          </div>
        </div>

        {showProgressModal ? (
          <ProgressModal
            {...{ setShowProgressModal }}
            goalId={id}
            setProgressStringState={setProgressStringState}
            setProgressPercentageState={setProgressPercentageState}
          />
        ) : null}
      </div>
    </>
  );
};

export default Goal;
