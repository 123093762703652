// CHAT SLICE SELECTOR'S //
export const chatMetadataSelector = (state) => state.chatSlice.chatMetadata;

export const messagesListFiltersSelector = (state) =>
  state.chatSlice.messagesListFilters;

export const selectedChatUserSelector = (state) =>
  state.chatSlice.selectedChatUser;

export const chatUsersSelector = (state) => state.chatSlice.chatUsers;

export const chatNotificationSelector = (state) =>
  state.chatSlice.chatNotification;

export const isRoleAllowedSelector = (state) => state.chatSlice.isRoleAllowed;

// export const chatItemsSelector = (state) => state.chatSlice.chatItems
export let chatItemsSelector = (state) => state.chatSlice.chatItems;

export const currentGroupItemSelector = (state) =>
  state.chatSlice.currentGroupItem;

// BASE API SLICE SELECTORS //

export const isProdSelector = (state) => state.baseApi.isProd;

export const baseApiUrlSelector = (state) => state.baseApi.baseApiUrl;

export const baseAwsUrlSelector = (state) => state.baseApi.baseAwsUrl;

// BLOCKSTATE SLICE  SELECTORS //

export const blockStateSelector = (state) => state.blockStateSlice.blockState;

// AUTH SLICE  SELECTORS //

export const tokenSelector = (state) => state.authSlice.token;

export const isAuthorizedSelector = (state) => state.authSlice.isAuthorized;

export const isLoadingSelector = (state) => state.authSlice.isLoading;

// USERTYPES  SLICE  SELECTORS //

export const userTypesSelector = (state) => state.userTypesSlice.userTypes;

// MEDIATOR  SLICE  SELECTORS //

export const mediatorSelector = (state) => state.mediatorSlice.mediator;

export const prevNavSelector = (state) => state.mediatorSlice.prevnav;

// USERDATA  SLICE  SELECTORS //

export const userDataSelector = (state) => state.userDataSlice.userData;

export const userNameSelector = (state) => state.userDataSlice.username;

// SLIDER  SLICE  SELECTORS //

export const sliderIndexSelector = (state) => state.sliderSlice.sliderIndex;
export const initialSlideSelector = (state) => state.sliderSlice.initialSlide;

// FILTERS  SLICE  SELECTORS //

export const filtersSelector = (state) => state.filtersSlice.filters;

export const metadataSelector = (state) => state.filtersSlice.metadata;

// events  SLICE  SELECTORS //

export const eventsTotalSelector = (state) => state.eventsSlice.eventsTotal;

// terms modal   SLICE  SELECTORS //

export const showModalSelector = (state) =>
  state.eventTermsModalSlice.showModal;
export const showlinkSelector = (state) => state.eventTermsModalSlice.showlink;
