import React from 'react';
import Select from 'react-select';
import cl from './Dropdowns.module.scss';

const Dropdown = ({
  handleChange,
  title,
  chargesType,
  handleEnterKeyPress,
  components,
  ...rest
}) => {
  const hoverColor =
    title === 'consigner'
      ? '#e2b3ff'
      : title === 'seller'
      ? '#caeadc'
      : '#dce4ef';
  const accentColor =
    title === 'consigner'
      ? '#641793'
      : title === 'seller'
      ? '#009364;'
      : '#203464';

  const styles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? accentColor : '',
      color: state.isSelected ? 'white' : '',
      '&:hover': {
        backgroundColor: state.isSelected ? '' : hoverColor,
      },
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isSelected ? accentColor : '',
      boxShadow: 'none',
      border: '1px solid #b1b1b1',
      borderRadius: chargesType ? 0 : '8px',
      borderRight: chargesType && 'none',
      borderTopLeftRadius: chargesType && '8px',
      borderBottomLeftRadius: chargesType && '8px',
      '&:hover': {
        border: `1px solid ${accentColor}`,
        boxShadow: 'none',
      },
    }),
  };

  return (
    <>
      <div className={cl.dropdownContainer}>
        <div className={cl.dropdownBox}>
          <label htmlFor="dropdown" className={cl.dropdownLabel}>
            <Select
              styles={styles}
              onChange={handleChange}
              components={components}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  if (handleEnterKeyPress) handleEnterKeyPress();
                }
              }}
              {...rest}
            />
          </label>
        </div>
      </div>
    </>
  );
};

//
//
//

export default Dropdown;
