import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import browseArt from '../../assets/picture/browseArt.png';
import connectArt from '../../assets/picture/connectArt.png';
import findArt from '../../assets/picture/findArt.png';
import landing from '../../assets/picture/landing.png';
import goals from '../../assets/picture/goals.png';
import {
  SVGblog,
  SVGpadlock,
  SVGterms,
  SVGinstagram,
  SVGpodcast,
  SVGfacebook,
  SVGfbGroup,
  SVGyoutube,
  SVGtiktok,
  SVGUpdatedLogoB,
  SVGUpdatedLogoC,
  SVGhomefont,
  SVGloginfont,
  SVGusers,
} from '../../assets/PackageSVG';
import Logo from '../../assets/svg/splash/lp.svg';
import LPButton from '../../components/ui/button/LPButton';
import cl from './HomePage.module.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './SliderStyles.css';
import CardItem from '../../components/Card/CardItem';
import { useSelector } from 'react-redux';
import { initialSlideSelector } from '../../redux/selectors';

const HomePage = () => {
  const navigate = useNavigate();

  const [readMore, setReadMore] = useState(false);
  const [allData, setAllData] = useState(true);

  const [mobileVersion, setMobileVersion] = useState(false);

  const initialSlide = useSelector(initialSlideSelector);

  const data = [
    {
      id: 1,
      name: 'Don N.',
      nameDesc: 'The Donovan Collection',
      desc: 'The listing party platform has greatly helped me improve my business. The interactions within the group make the events fun and friendly. The tips and tricks exchanged in the meetings have greatly elevated my business. I would like to thank the List Perfectly team for giving the resellers a platform to learn and grow as we build our business. LP is more than just a cross-posting service. It is a community of likeminded individuals interested in not only their success, but also in the success of others',
      color: '#0094FF',
      vector: 'vectorBlue',
      image: 'don.jpg',
    },
    {
      id: 2,
      name: 'Jenny H.',
      nameDesc: 'Kalahari Quilts',
      desc: "What fun, good chat with people who know what you're talking about even if it's silly, as well as good information. Night ones are my favorite hahaha. I will join again whenever I see one",
      color: '#7B61E7',
      vector: 'vectorPurple',
      image: 'jenny.jpg',
    },
    {
      id: 3,
      name: 'Samantha P.',
      nameDesc: 'Sorting with Samantha',
      desc: "It's hard to explain how great this community is. Resellers are a special breed and List Perfectly gets us. Listing Party is a whole new community of sellers. It's for the resellers made by resellers. The fact that people can join from anywhere is amazing. Listen, chat, or host. There are so many ways to interact, or not, while you absorb new information. Even the most experienced sellers are learning new things. Having a community of people like this is priceless. Thank you List Perfectly for extending our community and expanding our skills.",
      color: '#009566',
      vector: 'vectorGreen',
      image: 'samantha.jpg',
    },
    {
      id: 4,
      name: 'Vanessa P.',
      nameDesc: 'Butterflies & Gardenias',
      desc: 'Listing Party is like a group of best friends that you’re meeting at a favorite hangout in your pajamas. Listing Party is even better than that. It’s filled with riches. Gold, people that understand what you’re doing, how you are doing it and what it takes to do it. Silver, the comic relief and the virtual embrace for when you had one of those days. Bronze, the knowledge of experts who have been reselling for 20+ years at your fingertips, face-to-face. It’s amazing.  Thank you List Perfectly for creating Listing Party. I’m not sure what I did before it, but it probably included stress eating and tons of YouTube videos that didn’t answer my questions like you do.',
      color: '#0094FF',
      vector: 'vectorBlue',
      image: 'vanessa.jpeg',
    },
    {
      id: 5,
      name: 'Pam H.',
      nameDesc: '@whimsynsuch',
      desc: 'List Perfectly has been a game changer for my online business since I discovered 3 years ago. The constant upgrades and improvements are well thought out and often directly related to requests from those of us who use the platform daily. Never did I imagine that the addition of Listing Parties would have such an impact on me and my business. When it was first announced, I was a little hesitant about yet another social platform, but I am so glad that I decided (as part of my New Year’s Resolution to focus on business more) to start going to some Listing Parties to see what it was all about. My first Listing Party was the Daily Mastermind Call – yep it was the very first one on January 9th! I now make it a point to be on the call every morning if I can. What a great experience and a great group of like-minded people talking about the ins and outs of the online sales business. Every day I have jotted down at least one if not several things that I want to either research or start possibly doing differently.  It has been inspirational, motivational, fun, and last but certainly not least…it has brought results for my business in just the few short weeks of daily accountability. I have also attended quite a few of the other Listing Parties as I can fit them into my day and the content is always helpful. I would encourage anyone to jump in and look at the Events Schedule and find a Party to try. Listing 101 is a must for anyone that is new to List Perfectly or wants to drill deeper down into a feature. There really is something for everyone on List Perfectly Listing Parties and everyone is so welcoming. I can’t thank the Mastermind Gang and List Perfectly enough for literally changing how I go about my workday! Thank You!',
      color: '#7B61E7',
      vector: 'vectorPurple',
      image: 'pam.jpg',
    },
    {
      id: 7,
      name: 'Brian H.',
      nameDesc: '@Toysfromtheattic',
      desc: 'I’ve been a reseller for over 25 years and finding List Perfectly and Listing Party has been a game changer. I love the various Listing Parties for several reasons. I learn something every party, I get to help others which is very satisfying, and for a self-employed individual contributor (working by myself most of the day) I love the social aspect. The lessons learned keep coming and include best practices on printing labels, shipping, sourcing, and using social media, just to name a few. If you haven’t already tried attending a Listing Party, you should do so today. There’s one you can learn from each day and you can host your own! ',
      color: '#0094FF',
      vector: 'vectorBlue',
      image: 'brianNew.jpeg',
    },
    {
      id: 8,
      name: 'Nisha D.',
      nameDesc: '',
      desc: 'Listing Party is a game changer for my business! Thank you so much Amanda and Clara.',
      color: '#7B61E7',
      vector: 'vectorPurple',
      image: 'nisha.jpg',
    },
    {
      id: 9,
      name: 'Elizabeth H.',
      nameDesc: 'Lotuslizzy',
      desc: 'I love the added benefit of having access to Listing Party. The rollout of extra useful features has really enhanced my reselling abilities. It is great to have an additional directory for searchability, a place to create goals for my business, and daily masterminding events. They are super informative and fun to boot. I love love, love hanging, and learning from my fellow resellers. Thanks, Listing Perfectly!',
      color: '#009566',
      vector: 'vectorGreen',
      image: 'lotus.jpg',
    },
    {
      id: 10,
      name: 'Sarah M.',
      nameDesc: '(UpScrappy)',
      desc: 'My first Zoom participation was on Listing 101 with Theresa Cox. Theresa was very welcoming, calling everyone by name to welcome us in.  She also let us all know that every question is an important question that we can all learn from, which helped me be comfortable in asking questions. I particularly enjoyed her business attitude. I’m hoping to never miss a Listing 101 Zoom session with her.',
      color: '#0094FF',
      vector: 'vectorBlue',
      image: 'sarah.jpg',
    },
    {
      id: 11,
      name: 'Patricia C.',
      nameDesc: '@trishsattic',
      desc: 'Listing Party Mastermind Call - Daily Accountability I joined the Mastermind Zoom to keep me focused on my reselling business and it is working. It is also helping me fill in the gap of my knowledge in my various reseller processes. It’s been a joy to meet people of like minded business goals from around the country. I’m hoping to continue with this group indefinitely. @supersaletrish Trish has guided us through each day with expertise and humor. And I thank her.',
      color: '#7B61E7',
      vector: 'vectorPurple',
      image: 'patricia.jpeg',
    },
  ];
  const [sliderData, setSliderData] = useState([...data]);
  const sliderRef = useRef(null);

  const goToSlide = (index) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };
  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: readMore ? 1 : 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: readMore ? 1 : 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          dots: true,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const generateNewList = (index) => {
    setAllData(!allData);
    goToSlide(index);
  };

  const clickOnItem = (index) => {
    generateNewList(index);
    if (window.innerWidth <= '768') {
      setMobileVersion(!mobileVersion);
    }
  };
  return (
    <div className={cl.container}>
      <div className={cl.beforeBanner}>
        <div className={cl.first}>
          <div className={cl.firstLeft}>
            <h1>Why list alone? Make it a </h1>
            <div className={[cl.center, cl.box].join(' ')}>
              {/* <h1>Make it a </h1> */}
              <SVGUpdatedLogoC />
              <div className={cl.borderDiv}></div>
              <div className={cl.center}>
                <p>
                  You’re invited, come join listing parties! Participate in live
                  events, connect and share with sellers, find assistants, get
                  inspired, and much more. Don’t list alone, be part of the
                  party!
                </p>
              </div>
              <LPButton onClick={() => navigate('/login')}>
                Log In with List Perfectly
              </LPButton>
            </div>
          </div>
          <div className={cl.firstRight}>
            <img src={landing} alt="Landing" />
          </div>
        </div>
      </div>

      <div className={cl.second}>
        <div className={cl.topTxtDiv}>
          <div className={cl.colorDiv}>
            <h1>Listing Party is FREE</h1>
          </div>
          <div>
            <h1>for all List Perfectly members</h1>
          </div>
        </div>

        <div className={cl.center}>
          <p>
            The mission of Listing Party is to offer a safe and fun space where
            the online seller community learns, sources, hires, supports,
            inspires, and so much more.
          </p>
        </div>
        <LPButton
          onClick={() =>
            window.open('https://listperfectly.com/pricing/', '_blank')
          }
        >
          Not a member yet? Join now!
        </LPButton>
      </div>

      <div className={cl.middle}>
        <h3>With Listing Party, the possibilities are endless!</h3>
        <div className={cl.card}>
          <img src={findArt} alt="" />
          <div className={cl.cardTxt}>
            <div className={[cl.topTxt, cl.blue].join(' ')}>
              <h1>
                Connect with the community through live events and much more.
                Don’t do it alone!
              </h1>
            </div>
            <p>
              Connect with the community through live events and much more.
              Don’t do it alone!
            </p>
          </div>
        </div>

        <div
          className={[cl.card, cl.cardPurple].join('')}
          style={{
            flexDirection: window.innerWidth > 767 ? 'row-reverse' : 'column',
          }}
        >
          <img src={browseArt} alt="" className={cl.orderedImg} />
          <div className={cl.cardTxt}>
            <div className={[cl.topTxt, cl.purple].join(' ')}>
              <h1>Browse listings to shop and source from fellow partiers.</h1>
            </div>
            <p>Browse listings to shop and source from fellow partiers.</p>
          </div>
        </div>
        <div className={cl.card}>
          <img src={connectArt} alt="" />

          <div className={cl.cardTxt}>
            <div className={[cl.topTxt, cl.yellow].join(' ')}>
              <h1>
                Search and find assistants, virtual and onsite or consigners to
                help with your business!
              </h1>
            </div>
            <p>
              Search and find assistants, virtual and onsite or consigners to
              help with your business!
            </p>
          </div>
        </div>
        <div
          className={cl.card}
          style={{
            flexDirection: window.innerWidth > 767 ? 'row-reverse' : 'column',
          }}
        >
          <img src={goals} alt="" />
          <div className={cl.cardTxt}>
            <div className={[cl.topTxt, cl.green].join(' ')}>
              <h1>Create and track goals in one centralized location.</h1>
            </div>
            <p>Create business and personal goals to stay on track!</p>
          </div>
        </div>
      </div>
      <div className="slider-conteiner">
        <h1 className="headerTitle">Hear it from our members</h1>
        <div className={mobileVersion && readMore ? 'overlayMobile' : ''}></div>
        {mobileVersion && readMore ? (
          <div>
            <CardItem
              id={sliderData[initialSlide].id}
              name={sliderData[initialSlide].name}
              nameDesc={sliderData[initialSlide].nameDesc}
              desc={sliderData[initialSlide].desc}
              color={sliderData[initialSlide].color}
              vector={sliderData[initialSlide].vector}
              key={sliderData[initialSlide].id}
              setReadMore={setReadMore}
              readMore={readMore}
              clickEvent={clickOnItem}
              mobileVersion={mobileVersion}
              index={sliderData[initialSlide].id - 1}
              image={sliderData[initialSlide].image}
            />
          </div>
        ) : (
          <Slider {...settings} ref={sliderRef}>
            {sliderData.map((item, index) => {
              return (
                <CardItem
                  id={item.id}
                  name={item.name}
                  nameDesc={item.nameDesc}
                  desc={item.desc}
                  color={item.color}
                  vector={item.vector}
                  key={item.id}
                  setReadMore={setReadMore}
                  readMore={readMore}
                  index={index}
                  clickEvent={clickOnItem}
                  mobileVersion={mobileVersion}
                  image={item.image}
                />
              );
            })}
          </Slider>
        )}
      </div>

      <div className={cl.bottomList}>
        <h3 className={cl.sliderTitle}>Stay connected!</h3>
        <div className={cl.iconsContainer}>
          <div className={cl.iconsDivs}>
            <SVGinstagram
              className={cl.icon}
              onClick={() =>
                window.open(
                  'https://www.instagram.com/listingpartyinc/',
                  '_blank',
                )
              }
            />
            <SVGtiktok
              className={cl.icon}
              onClick={() =>
                window.open(
                  'https://www.tiktok.com/@listingparty?_t=8WP11LmDnJO&_r=1',
                  '_blank',
                )
              }
            />
            <SVGpodcast
              className={cl.icon}
              onClick={() =>
                window.open('https://thesellercommunitypodcast.com/', '_blank')
              }
            />
            <SVGfacebook
              className={cl.icon}
              onClick={() =>
                window.open('https://www.facebook.com/ListingParty', '_blank')
              }
            />

            {/* <SVGfacebook className={cl.icon} onClick={()=>window.open('', '_blank')} /> */}
            <SVGblog
              className={cl.icon}
              onClick={() =>
                window.open('https://listperfectly.com/blog/', '_blank')
              }
            />
          </div>
        </div>
      </div>

      <div className={cl.footer}>
        <div className={cl.footerTop}>
          <div onClick={() => navigate('/')}>
            <SVGhomefont fill='#fff' width={27} />
            Home{' '}
          </div>
          <div 
            className={cl.about} 
            onClick={() => navigate('https://listingparty.com/')}
          >
            <SVGusers fill='#fff' width={27} />
            About us{' '}
          </div>
          <div
            onClick={() =>
              window.open('https://listperfectly.com/pricing/', '_blank')
            }
          >
            <SVGloginfont fill='#fff' width={27}/>
            Sign Up for List Perfectly
          </div>
        </div>

        <div className={cl.footerTop}>
          <div>
            <span style={{ fontSize: '25px' }}>
              &copy; Listing Party<sup>TM</sup> - {new Date().getFullYear()}.
              All Rights Reserved.
            </span>
          </div>
          <div onClick={() => navigate('/')} className={cl.privacy}>
            <SVGpadlock />
            Privacy
          </div>
          <div onClick={() => navigate('/')} className={cl.terms}>
            <SVGterms />
            Terms of Service
          </div>
        </div>
        <div className={cl.footerLogo}>
          <SVGUpdatedLogoB />
        </div>

        <p className={cl.mobileRights}>
          &copy; Listing Party<sup>TM</sup> - {new Date().getFullYear()}. All
          Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default HomePage;
