import LPButton from '../../components/ui/button/LPButton.jsx';
import { useNavigate } from 'react-router-dom';

import Container from '../../components/Container/Container.jsx';
import ErrorImg from '../../assets/svg/error_page/error_page_oops.svg';
import Cleaning_hat from '../../assets/svg/error_page/cleaning_hat_maintenance.svg';

import cl from './Maintenance.module.scss';

//
//
//

const MaintenancePage = () => {
  const navigate = useNavigate();

  return (
    <Container className={cl.wrapper}>
      {/* <h2>We are working on things and will be back soon...</h2> */}
      {/* <div className={cl.text}>{text || 'Something went wrong'}</div> */}
      {/* 	{!reason ? null : (
				<div className={cl.reason}>
					<div>Why do I see this?</div>
					<div>{reason}</div>
				</div>
			)} */}

      {/* <LPButton className={cl.btn_back} onClick={()=>navigate('/')}>
				Back to Homepage
			</LPButton> */}
      <img src={Cleaning_hat} alt="show error" />
      <div>
        We’re just cleaning up a bit to make Listing Party better for you.
      </div>
      <div>Check back soon!</div>
      <LPButton onClick={() => navigate('/')}>Back to Home Page</LPButton>
    </Container>
  );
};

//
//
//

export default MaintenancePage;
