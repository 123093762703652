import React from 'react';
import CreatableSelect from 'react-select/creatable';
import cl from './Dropdowns.module.scss';

const DropdownWithInput = ({
  placeholder,
  value,
  handleChange,
  options,
  isMulti,
  handleEnterKeyPress,
  components,
  isClearable,
  type,
  id,
}) => {
  const hoverColor =
    type === 'consigner'
      ? '#e2b3ff'
      : type === 'seller'
      ? '#caeadc'
      : '#dce4ef';
  const accentColor =
    type === 'consigner'
      ? '#641793'
      : type === 'seller'
      ? '#009364;'
      : '#203464';

  const styles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? accentColor : '',
      color: state.isSelected ? 'white' : '',
      overflow: 'auto',
      textOverflow: 'unset',
      whiteSpace: 'unset',
      '&:hover': {
        backgroundColor: state.isSelected ? '' : hoverColor,
      },
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isSelected ? accentColor : '',
      border: '1px solid #b1b1b1',
      borderRadius: '8px',
      boxShadow: 'none',
      '&:hover': {
        border: `1px solid ${accentColor}`,
        boxShadow: 'none',
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      overflow: 'auto',
      textOverflow: 'unset',
      whiteSpace: 'unset',
    }),
  };

  return (
    <>
      <div className={cl.dropdownContainer}>
        <div className={cl.dropdownBox}>
          <label htmlFor="dropdownWithTags" className={cl.dropdownLabel}>
            <CreatableSelect
              isMulti={isMulti}
              options={options}
              isClearable={isClearable}
              placeholder={placeholder}
              styles={styles}
              onChange={handleChange}
              value={value}
              components={components}
              id={id}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  if (handleEnterKeyPress) handleEnterKeyPress();
                }
              }}
            />
          </label>
        </div>
      </div>
    </>
  );
};

export default DropdownWithInput;
