import React, { Component } from 'react';
import Tooltip from '../../../components/ui/tooltip/Tooltip';
import cl from './FormNotes.module.scss';

const FormNotes = ({ setSaveBtnDisabled, notes, setNotes, title }) => {
  const handleNotesChange = ({ target }) => {
    setSaveBtnDisabled((prev) => ({ ...prev, [title.toLowerCase()]: false }));
    setNotes(target.value);
  };
  return (
    <>
      <label htmlFor="notes" className={cl.noteContainer}>
        <label htmlFor={`notes${title}`}>
          <h2>{title} Notes</h2>
        </label>
        <Tooltip>
          <p>
            Use this section to talk about your experience, specialty, payment
            methods and rates, and anything else you think is important for
            partiers to know.{' '}
          </p>
        </Tooltip>
        <div className={cl.notesDiv}>
          <textarea
            id={`notes${title}`}
            className={cl.notes}
            type="text"
            defaultValue={notes}
            onBlur={handleNotesChange}
            rows="5"
          />
        </div>
      </label>
    </>
  );
};

export default FormNotes;
