import React, { useEffect, useRef, useState } from 'react';
import './year.css';

import useBasicFuncs from '../../../../hooks/useBasicFuncs';
// import CalendarButton from "../components/CalendarButton";
import { SVGCloseIcon } from '../../../../assets/PackageSVG';
import AddCalendar from '../AddCalendar';

const YearTooltip = ({ eventLists, choosenDate, handleClose, ref }) => {
  const { bdecode } = useBasicFuncs();

  const date = new Date(choosenDate);
  const month = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();

  const yearTooltipRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!yearTooltipRef?.current?.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [yearTooltipRef]);

  return (
    <div className="modalDirectory" ref={yearTooltipRef}>
      <div className="header-container">
        <div className="closeModal" onClick={handleClose}>
          {/* <img src={closeIcon} alt='close' /> */}
          <SVGCloseIcon />
        </div>
        <div>
          <span>
            {day - 1} {month}
          </span>
        </div>
      </div>
      <div style={{ marginBottom: 65 }}></div>
      <div
        className="content-container"
        style={{
          height: eventLists.length > 3 && '390px',
          padding: eventLists.length > 3 && '10px 0',
        }}
      >
        {eventLists.length !== 0 ? (
          eventLists?.map((item) => {
            return (
              <div className="wrapper">
                <div className="topPart">
                  {item.data.thumbnail ? (
                    <div className="left">
                      {' '}
                      <img src={item.data.thumbnail} alt="event imagery" />{' '}
                      {/*{live && <SVGlive/>}*/}
                    </div>
                  ) : null}
                  <div className="top">
                    <div className="leftSide">
                      <h2>{bdecode(item.data.title)}</h2>
                      <div className="bottom">
                        <p>{bdecode(item.data.description)}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="rightSide">
                  {/* <CalendarButton
                                        width={210}
                                        title={item.data.title}
                                        description={item.data.description}
                                        date={item.date}
                                    /> */}
                  <AddCalendar
                    title={item.data.title}
                    description={item.data.description}
                    date={item.date}
                  />
                </div>
              </div>
            );
          })
        ) : (
          <div style={{ padding: 20 }}>No events</div>
        )}
      </div>
    </div>
  );
};

export default YearTooltip;
