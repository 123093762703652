import React from 'react';
import cl from './Input.module.scss';

const Input = ({
  id,
  onChange,
  data,
  addClass,
  maxLength,
  required,
  name,
  index,
  dataKey,
  placeholder,
  inputType,
  minValue,
  title,
  step,
  setSiteInput,
  active,
  disabled,
  type,
}) => {
  const onBlurHandler = (name) => {
    if (name !== 'site') return;
    setSiteInput((prev) =>
      prev.map((obj) => {
        if (obj.id === index) {
          return { ...obj, status: true };
        }
        return obj;
      }),
    );
  };
  const inputClass = [
    cl.input,
    cl[addClass],
    cl[active ? `active${type}` : ''],
  ].join(' ');
  return (
    <>
      <input
        {...{ maxLength, name, required, placeholder, step, title }}
        type={inputType}
        className={inputClass}
        value={dataKey ? data[dataKey] : data ? data : ''}
        onChange={({ target }) => onChange(target, index, 'value')}
        data-key={dataKey}
        min={minValue}
        onBlur={() => onBlurHandler(name)}
        id={id}
        disabled={disabled}
      />
    </>
  );
};

export default Input;
