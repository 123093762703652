import React from 'react';

import UnlockedPadlock from '../../../assets/svg/profile_form/unlocked_padlock.svg';
import LockedPadlock from '../../../assets/svg/profile_form/locked_padlock.svg';

import cl from './Padlock.module.scss';

const Padlock = ({ usernameDisabled, trySaveUsername, text }) => {
  return (
    <div
      className={
        usernameDisabled
          ? [cl.padlockContainer, cl.disabled].join(' ')
          : cl.padlockContainer
      }
      onClick={trySaveUsername}
    >
      <span className={cl.txt}>
        {text ? text : usernameDisabled ? 'Click to Change!' : 'Save Changes!'}
      </span>
      <img
        className={cl.icon}
        src={usernameDisabled ? LockedPadlock : UnlockedPadlock}
        alt="Account Icon"
      ></img>
    </div>
  );
};

export default Padlock;
