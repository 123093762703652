import React from 'react';

import _ from 'lodash';

import { SVGdollarsign } from '../../../../assets/PackageSVG';

import RemoveIcon from '../../../../components/ui/removeIcon/RemoveIcon';
import Input from '../../../../components/ui/input/Input';
import Dropdown from '../../../../components/ui/dropdowns/Dropdown';

import cl from './ChargesInput.module.scss';

const optionsServices = [
  { value: 'Flat fee', label: 'Flat fee', valueKey: '1' },
  { value: 'General Assistant', label: 'General Assistant', valueKey: '2' },
  { value: 'Bookkeeping', label: 'Bookkeeping', valueKey: '3' },
  { value: 'Coaching', label: 'Coaching', valueKey: '4' },
  { value: 'Consignment', label: 'Consignment', valueKey: '5' },
  { value: 'Crossposting', label: 'Crossposting', valueKey: '6' },
  {
    value: 'Fulfillment & Shipping',
    label: 'Fulfillment & Shipping',
    valueKey: '7',
  },
  { value: 'Listing creation', label: 'Listing creation', valueKey: '8' },
  { value: 'Listing review', label: 'Listing review', valueKey: '9' },
  { value: 'Photography', label: 'Photography', valueKey: '10' },
  { value: 'Sourcing', label: 'Sourcing', valueKey: '11' },
];

const optionsServicesPrice = [
  { value: 'per_hour', label: 'per hour' },
  { value: 'per_item', label: 'per item' },
  { value: 'percentage', label: 'percentage' },
];

const ChargesInput = ({
  index,
  typeValue,
  priceValue,
  servValue,
  charges,
  setCharges,
  setSaveBtnDisabled,
  type,
}) => {
  let addClass = true;

  const handleChargesChange = (e, id, label) => {
    setSaveBtnDisabled((prev) => ({ ...prev, [type]: false }));
    if (!e) {
      const updatedCharges = [...charges];
      updatedCharges[id][label] = '';
      setCharges(updatedCharges);
      return;
    }
    let { value } = e;
    let newValue =
      label === 'value' ? parseFloat(e) : label === 'name' ? value : e;
    const updatedCharges = [...charges];
    updatedCharges[id][label] = newValue;
    setCharges(updatedCharges);
  };

  const handleRemoveCharge = (e, index) => {
    setSaveBtnDisabled((prev) => ({ ...prev, [type]: false }));
    if (index === 0 && charges.length <= 1) {
      setCharges([{ name: '', value: '', type: '', currency: 'usd' }]);
      return;
    }
    charges.splice(index, 1);
    let updatedCharges = [...charges];
    setCharges(updatedCharges);
  };

  const step =
    typeValue === 'per_hour' || typeValue === 'per_item' ? '.25' : '.5';
  const placeholder =
    typeValue === 'per_hour' || typeValue === 'per_item' ? '0.00' : '0';

  return (
    <>
      <div className={cl.chargesContainer}>
        <div className={cl.chargesBox}>
          <label htmlFor="serviceCharges" className={cl.chargesService}>
            <Dropdown
              id="servicesCharges"
              value={{ value: servValue, label: servValue }}
              isClearable={true}
              isSearchable={true}
              options={optionsServices}
              placeholder="Add Service"
              onChange={(e) => handleChargesChange(e, index, 'name')}
              title={type}
            />
          </label>
          <label
            htmlFor="typesCharges"
            className={
              servValue
                ? cl.chargesItem
                : [cl.chargesItem, cl.disabled].join(' ')
            }
          >
            <Dropdown
              id="typesCharges"
              options={optionsServicesPrice}
              placeholder="per hour"
              defaultValue={{ label: 'per hour', value: 'per_hour' }}
              onChange={({ value }) =>
                handleChargesChange(value, index, 'type')
              }
              value={{ value: typeValue, label: typeValue.replace('_', ' ') }}
              title={type}
              chargesType={true}
            />
          </label>
          <div
            className={
              servValue ? cl.dollarSign : [cl.dollarSign, cl.disabled].join(' ')
            }
          >
            {typeValue === 'percentage' ? (
              '%'
            ) : (
              <SVGdollarsign width={16} height={16} />
            )}
          </div>
          <label
            htmlFor="amountCharges"
            className={
              servValue && type === 'consigner'
                ? [cl.chargesPrice, cl.consigner].join(' ')
                : !servValue
                ? [cl.chargesPrice, cl.disabled].join(' ')
                : cl.chargesPrice
            }
          >
            <Input
              id="amountCharges"
              maxLength="5"
              required={false}
              placeholder={placeholder}
              addClass="price"
              data={priceValue}
              index={index}
              onChange={({ value }) =>
                handleChargesChange(value, index, 'value')
              }
              inputType="number"
              minValue="0"
              step={step}
            />
          </label>
          {index >= 0 || !index ? (
            <RemoveIcon
              removeField={handleRemoveCharge}
              index={index}
              addClass={addClass}
            />
          ) : (
            <div className={cl.removeBtnBox}></div>
          )}
        </div>
      </div>
    </>
  );
};

export default ChargesInput;
