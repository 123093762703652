import React, { useState } from 'react';
import Container from '../components/Container/Container';

const RedirectPage = ({ path }) => {
  useState(() => {
    // window.location.href = path;

    setTimeout(() => {
      window.open(path, '_blank');
    }, 2000);
  }, [path]);

  return (
    <Container>
      <h2>Redirecting to...</h2>

      <span>Click if your browser does not support redirect</span>

      <span>
        <a href={path} target="_blank" rel="noreferrer">
          {path}
        </a>
      </span>
    </Container>
  );
};

export default RedirectPage;
