import React, { useEffect, useRef } from 'react';

import styles from './ReportMessageModal.module.scss';
import ModalButton from '../../../../../../components/ui/ModalButton/ModalButton';

import _ from 'lodash';

function ReportMessageModal(props) {
  const { message, handleChangeReported } = props;

  const reportMessageRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        reportMessageRef.current &&
        !reportMessageRef.current.contains(event.target)
      ) {
        handleChangeReported();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [reportMessageRef]);

  return (
    <div className={styles.reportMessage__overlay}>
      <div ref={reportMessageRef} className={styles.modal}>
        <h1>Are you sure you want to report this message?</h1>
        <div
          className={styles.modal__content}
          dangerouslySetInnerHTML={{ __html: _.unescape(message) }}
        />
        <div className={styles.modal__btns}>
          <ModalButton feature="light" cb={handleChangeReported}>
            No, exit
          </ModalButton>
          <ModalButton feature="primary">Yes, Report this message</ModalButton>
        </div>
      </div>
    </div>
  );
}

export default ReportMessageModal;
