import React, { useEffect } from 'react';

import _, { unescape } from 'lodash';

import styles from './ChatUserListContainer.module.scss';

import ellipsePic from '../../../../../../assets/picture/Ellipse 85.svg';
import profilePic from '../../../../../../assets/picture/profile.jpg';
import { useNavigate, useParams } from 'react-router-dom';
import { useGlobalContext } from '../../../../../../contexts/Context';
import UserSharedWorker from '../../../../../../hooks/UserSharedWorker';
import useUsers from '../../../../../../hooks/useUsers';

export const ChatUserListContainer = (props) => {
  const { id, chatUser, createdAt, message, type, chatItem } = props;
  const { name, photo, username } = chatUser;
  const { first_name, last_name } = name;

  const USER_PHOTO = photo?.key
    ? `https://s3.us-east-1.amazonaws.com/cdn.listingparty.com/${photo?.key}`
    : profilePic;

  const { user_id } = useParams();

  const { dispatch, state } = useGlobalContext();

  const { chatItems } = state;

  const { messagesActionsAdd } = useUsers();

  const { sharedDataSingleUser } = UserSharedWorker();

  const navigate = useNavigate();

  useEffect(() => {
    chatItems.map((item) => {
      if (item.id === sharedDataSingleUser.chat_id) {
        if (item.message !== null) {
          item.message.message = sharedDataSingleUser.message;
          item.message.actions = null;
        }
      }
    });

    dispatch({
      type: 'CHATITEMS',
      payload: chatItems,
    });
  }, [sharedDataSingleUser]);

  const handleChangeResponsiveView = () => {
    const leftBox = document.querySelector('.leftBox');
    const rightBox = document.querySelector('.rightBox');

    if (window.innerWidth <= 768) {
      leftBox.style.display = 'none';
      rightBox.style.display = 'block';
    }
  };

  const handleClick = async (id, messageId) => {
    dispatch({
      type: 'MESSAGESLIST',
      payload: { chat_id: id },
    });

    dispatch({
      type: 'CHATMETADATA',
      payload: { offset: 0, limit: 10 },
    });

    handleChangeResponsiveView();

    dispatch({
      type: 'SELECTEDCHATUSER',
      payload: true,
    });

    if (messageId) {
      try {
        const isRead = 'seen';
        const data = await messagesActionsAdd(messageId, isRead, {});

        if (data?.data) {
          const updatedChatItems = chatItems.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                message: {
                  ...item.message,
                  actions: data.data.action,
                },
              };
            }
            return item;
          });

          dispatch({
            type: 'CHATITEMS',
            payload: updatedChatItems,
          });
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      const updatedChatItems = chatItems.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            message: {
              ...item.message,
              actions: 'seen',
            },
          };
        }
        return item;
      });

      dispatch({
        type: 'CHATITEMS',
        payload: updatedChatItems,
      });
    }

    navigate(`/chat/${id}`, { state: { chatUser, type } });
  };

  return (
    <div
      className={
        id !== user_id
          ? styles.chatUserListContainer
          : `${styles.chatUserListContainer} ${styles.selectedUser}`
      }
      onClick={() => handleClick(id, message?.id)}
    >
      <div className={styles.chatUserListContainer_wrapper}>
        <div
          style={{
            visibility:
              chatItem?.message?.actions === null
                ? // && user_id !== sharedDataSingleUser.chat_id
                  'visible'
                : 'hidden',
          }}
          className={styles.isRead}
        >
          <img src={ellipsePic} alt="ellipse" />
        </div>
        <div className={styles.avatar}>
          <img src={USER_PHOTO} alt="chatUserAvatar" />
        </div>
        <div className={styles.chatUserInfo}>
          <div>
            <span>
              {first_name} {last_name}
            </span>
            <span className={styles.messageDateTime}>
              {sharedDataSingleUser.chat_id === id
                ? sharedDataSingleUser.created_time
                : createdAt}
            </span>
          </div>
          <span>@{username}</span>
          <span
            className={
              chatItem?.message?.actions === null
                ? // && user_id !== sharedDataSingleUser.chat_id
                  `${styles.previewText} ${styles.previewTextBold}`
                : styles.previewText
            }
          >
            {React.createElement('div', {
              dangerouslySetInnerHTML: {
                __html: _.unescape(message?.message),
              },
            })}
          </span>
        </div>
      </div>
    </div>
  );
};
