import EarlyBird from '../../assets/svg/earlyBird.svg';
import HostBadge from '../../assets/svg/hostBadge.svg';
import ListerBadge from '../../assets/svg/listerBadge.svg';

export const badgeArr = [
  {
    id: 0,
    title: 'Early Bird',
    type: 'early_adopter',
    imgSrc: EarlyBird,
    howToAdd: '',
    date: 'Joined the party in 2022.',
    description: '',
  },
  {
    id: 1,
    imgSrc: ListerBadge,
    title: 'Lister',
    type: 'listing',
    date: 'Coming Soon',
    howToAdd: 'How to earn this badge',
    description:
      'Share your listings with other partiers so they can source from you. Share by crossposting listings from your List Perfectly catalog to Listing Party. This option is available to all Business and Pro Plan members. ',
  },
  {
    id: 2,
    title: 'Host',
    imgSrc: HostBadge,
    type: 'host',
    date: 'Coming Soon',
    howToAdd: 'How to earn this badge',
    description:
      "Get the party started and share your knowledge! You too can host a live Listing Party. Sign Up <a style='font-weight:600' href='https://app.listperfectly.com/listing-party/host-signup/'> here </a>. Haven’t attended a live Listing Party before? Live events can be seen <a style='font-weight:600' href='/events'> here. </a>",
  },
  {
    id: 3,
    title: 'Early Bird',
    type: 'early_adopter2',
    imgSrc: EarlyBird,
    date: 'Joined the party in 2022. ',
    howToAdd: '',
    description: '',
  },
  {
    id: 4,
    imgSrc: ListerBadge,
    title: 'Lister',
    type: 'listing2',
    date: 'Coming Soon',
    howToAdd: 'How to earn this badge',
    description:
      'Share your listings with other partiers so they can source from you. Share by crossposting listings from your List Perfectly catalog to Listing Party. This option is available to all Business and Pro Plan members. ',
  },
  {
    id: 5,
    title: 'Host',
    imgSrc: HostBadge,
    type: 'host2',
    date: 'Coming Soon',
    howToAdd: 'How to earn this badge',
    description:
      "Get the party started and share your knowledge! You too can host a live Listing Party. Sign Up <a style='font-weight:600' href='https://app.listperfectly.com/listing-party/host-signup/'> here </a>. Haven’t attended a live Listing Party before? Live events can be seen <a style='font-weight:600' href='/events'> here.</a>",
  },
];
