import { configureStore } from '@reduxjs/toolkit';
import chatSlice from '../slices/chatSlice';
import baseApi from '../slices/baseApiSlice';
// import blockStateSlice from '../slices/blockStateSlice'
import authSlice from '../slices/authSlice';
import userTypesSlice from '../slices/userTypesSlice';
import mediatorSlice from '../slices/mediatorSlice';
import userDataSlice from '../slices/userDataSlice';
import sliderSlice from '../slices/sliderSlice';
import filtersSlice from '../slices/filtersSlice';
import eventTermsModalSlice from '../slices/eventTermsModalSlice';
import eventsSlice from '../slices/eventsSlice';

import blockStateSlice from '../slices/bloskStateSlice';

export const store = configureStore({
  reducer: {
    chatSlice,
    baseApi,
    blockStateSlice,
    authSlice,
    userTypesSlice,
    mediatorSlice,
    userDataSlice,
    sliderSlice,
    filtersSlice,
    eventsSlice,
    eventTermsModalSlice,
  },
});
