import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import { setAuth } from '../redux/slices/authSlice';

const Logout = () => {
  const dispatchRedux = useDispatch();

  const navigate = useNavigate();

  //

  useEffect(() => {
    delete localStorage.lp_custom_access_token;

    dispatchRedux(setAuth({ token: '', isAuthorized: false }));

    navigate('/');
  });

  return <></>;
};

export default Logout;
