import React, { useEffect, useState } from 'react';

import _ from 'lodash';

import Tooltip from '../../../components/ui/tooltip/Tooltip';
import DropdownWithInput from '../../../components/ui/dropdowns/DropdownWithInput';

import cl from './FormServices.module.scss';

const FormServices = ({ setSaveBtnDisabled, services, setServices, type }) => {
  const options = [
    { value: 'General Assistant', label: 'General Assistant', valueKey: '1' },
    { value: 'Bookkeeping', label: 'Bookkeeping', valueKey: '2' },
    { value: 'Coaching', label: 'Coaching', valueKey: '3' },
    { value: 'Consignment', label: 'Consignment', valueKey: '4' },
    { value: 'Crossposting', label: 'Crossposting', valueKey: '5' },
    {
      value: 'Fulfillment and Shipping',
      label: 'Fulfillment and Shipping',
      valueKey: '6',
    },
    { value: 'Listing creation', label: 'Listing creation', valueKey: '7' },
    { value: 'Photography', label: 'Photography', valueKey: '8' },
    { value: 'Sourcing', label: 'Sourcing', valueKey: '9' },
  ];
  const [servValue, setServValue] = useState();

  useEffect(() => {
    let arr = [];
    if (services && services.length > 0) {
      for (let i = 0; i <= services.length; i++) {
        if (services[i]) {
          let obj = {};
          obj.value = _.unescape(services[i]);
          obj.label = _.unescape(services[i]);
          obj.valueKey = i;
          arr[i] = obj;
        }
      }
      setServValue(arr);
    }
  }, [services]);

  const handleServicesChange = (e) => {
    setSaveBtnDisabled((prev) => ({ ...prev, [type]: false }));
    setServValue(e);
    let servArr = e.map((el) => el.value);
    setServices(servArr);
  };
  return (
    <>
      <div className={cl.heading}>
        <div style={{ display: 'flex' }}>
          <h2>What services do you offer?</h2>
          <Tooltip>
            {/* <span>some text</span> */}
            <ul>
              <li>
                <b>General Assistant</b>- Services that don’t fall under any of
                the other categories here. These may include organizing, tagging
                products, research, inventory management, etc.
              </li>
              <br />
              <li>
                <b>Accounting / Bookkeeping </b>- Services related to business
                expense tracking, receipt management, business tax preparation
                etc.{' '}
              </li>
              <br />
              <li>
                <b>Coaching </b>- Services for offering expert 1-on-1 help or
                mentoring about specific business needs or strategies.
              </li>
              <br />
              <li>
                <b>Crossposting </b>- Services related to copying listings from
                the List Perfectly catalog to marketplaces or directly from
                marketplaces to marketplaces.
              </li>
              <br />
              <li>
                <b>Shipping / Fulfillment</b> - Services related to product
                storage, packaging, shipping, organizing pickups, etc.{' '}
              </li>
              <br />
              <li>
                <b>Listing creation </b>- Services related to creating or
                drafting product listings. Including research, taking or
                inserting photos, title building, description building, pricing,
                etc.
              </li>
              <br />
              <li>
                <b>Listing review </b>- Services offered may include analyzing
                existing listings (title, description, photographs, etc.), SEO
                optimization, error correction and spellchecking, etc.
              </li>
              <br />
              <li>
                <b>Photography </b>- Services offered may include photographing
                products or goods for listing creation.{' '}
              </li>
              <br />
              <li>
                <b>Sourcing </b>- Services offered may include shopping,
                selecting, and purchasing products or goods on behalf of someone
                else.{' '}
              </li>
              <br />
            </ul>
          </Tooltip>
        </div>
        <p>Select all that apply or add your own.</p>
      </div>

      <DropdownWithInput
        isMulti={true}
        type={type}
        isClearable={true}
        placeholder="Select Service"
        handleChange={handleServicesChange}
        value={servValue}
        {...{ type, options }}
      />
    </>
  );
};

export default FormServices;
