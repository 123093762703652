import Container from './Container';

//
//
//

const Loader = ({ children, isFetching, isLoading, ...rest }) => {
  return (
    <>
      {isFetching || isLoading ? (
        <Container {...rest} isLoading={isFetching || isLoading}></Container>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

//
//
//

export default Loader;
