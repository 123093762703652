import React, { useState } from 'react';
import badge from '../../assets/svg/badge.svg';
// import BadgeModal from '../Modal/BadgeModal'
import Modal from '../Modal/Modal';
import cl from './DirectoryBadge.module.scss';
import { capitalizedLetter, removeUnderscore } from './utils/removeUnderscore';

export default function DirectoryBadge({ count, user, badges }) {
  const [isModal, setIsModal] = useState(false);

  function onMouseOver() {
    setIsModal(true);
  }

  function onMouseOut() {
    setIsModal(false);
  }

  return (
    <>
      <div
        className={cl.wrapper}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      >
        <img src={badge} alt="badge" className={cl.img} />
        <div className={cl.badgeCount}>{count}</div>
        {isModal && (
          <Modal
            count={badges.map((item) => (
              <p key={item}>{removeUnderscore(item)}</p>
            ))}
            isProfile={false}
          />
        )}
      </div>
    </>
  );
}
