const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // local ip v6
    window.location.hostname === '[::1]' ||
    // local ip
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);

const unregister = async () => {
  if (!('serviceWorker' in navigator)) return false;

  const reg = await navigator.serviceWorker.ready.catch((e) => ({
    err: e.message,
  }));
  if (reg.err || !reg) console.error('[sw_unreg] err', reg?.err || reg);

  return reg.unregister();
};

const register = (config) => {
  if (!('serviceWorker' in navigator)) {
    console.error(
      '[swr] register err: No "serviceWorker" support in navigator!',
    );
    return false;
  }

  const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
  if (publicUrl.origin !== window.location.origin) return false;

  //console.log('[swr] registering... waiting onload state:', publicUrl.href);

  window.onload = async () => {
    const swUrl = `${
      process.env.PUBLIC_URL || publicUrl.origin
    }/service-worker.js`;

    if (!isLocalhost) {
      //console.log('[swr] sw_url:', swUrl);
      return serviceWorkerRegistration(swUrl, config);
    }

    if (process.env.NODE_ENV === 'production') {
      alert('tell Vadim');
      //console.log('[swr] \n\n\ntell Vadim\n\n\n');
    } else {
      return navigator.serviceWorker.ready.then((r) =>
        r.unregister().then(() => window.location.reload()),
      );
      // return serviceWorkerRegistration(swUrl, config);
    }
  };
};

const LOCAL_checkValidServiceWorker = (swUrl, config) => {
  fetch(swUrl, { headers: { 'Service-Worker': 'script' } })
    .then((response) => {
      const contentType = response.headers.get('content-type');
      /* 
				console.log('[sw_check] check', [
					swUrl,
					contentType,
					response,
					//
					response.status === 404,
					contentType != null,
					contentType.indexOf('javascript') === -1,
				]); */

      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        navigator.serviceWorker.ready.then((registration) => {
          //console.log('RED READY 1');

          registration.unregister().then(() => window.location.reload());
        });
      } else {
        serviceWorkerRegistration(swUrl, config);
      }
    })
    .catch((e) => {
      //console.log('[sw_check] err', e.message);
    });
};

const thirdPartyButNot = async () => {
  try {
    //console.log('[notification] type:', typeof Notification + '-&-' + Notification.permission);
    if (!('PushManager' in window))
      throw new Error('No "Push Api" support in window!');

    //
    //
    //

    // Notification.requestPermission((permission) => {
      // If the user accepts, let's create a notification
      // if(permission === 'granted'){
      // 	const notification = new Notification('Hi there!');
      // }
    // });

    //
    //
    //

    // window.Notification.requestPermission((result) => {
      //console.log(`[notification] can: ${result === 'granted'} (${result})`);
      // if(result !== 'granted'){console.log('[notification] false, user say:', result);}
      // else{console.log('[notification] true, user say:', result);}
      // reg.pushManager.getSubscription().then((something)=>{
      // 	console.log('[notification] sub:', something);
      // });
      // reg.pushManager.subscribe({userVisibleOnly: true});
      /* reg.pushManager.getSubscription().then((subscription)=>{
			// Enable any UI which subscribes / unsubscribes from
			// push messages.
			var pushButton = document.querySelector('.js-push-button');
			pushButton.disabled = false;

			if(!subscription){
				// We aren't subscribed to push, so set UI
				// to allow the user to enable push
				return;
			}

			// Keep your server in sync with the latest subscriptionId
			sendSubscriptionToServer(subscription);
			// In sendSubscriptionToServer() you will need to consider how you handle failed network requests when updating the endpoint.
			// One solution is to track the state of the endpoint in a cookie to determine whether your server needs the latest details or not.

			// Set your UI to show they have subscribed for
			// push messages
			pushButton.textContent = 'Disable Push Messages';
			isPushEnabled = true;
		}).catch(function (err){
			console.warn('Error during getSubscription()', err);
		}); */
      // const title = 'Notification 1 of 3';
      // const options = {
      // 	body: "With 'tag' of 'message-group-1'",
      // 	tag: 'message-group-1',
      // };
      // reg.showNotification(title, options);
    // });
  } catch (e) {
    console.error('[notification] err:', e.message);
  }

  try {
    navigator
      .setAppBadge(7)
      .then(() => {
        setTimeout(() => {
          navigator.clearAppBadge().catch((error) => {});
        }, 1000);
      })
      .catch((e) => console.error('[setAppBadge] err', e.message));
  } catch (e) {
    console.error('[setAppBadge] err 2:', e.message);
  }
};

const serviceWorkerRegistration = async (swUrl, config) => {
  if (!('serviceWorker' in navigator)) {
    console.error(
      '[sw_reg] register err: No "serviceWorker" support in navigator!',
    );
    return false;
  }

  const reg = await navigator.serviceWorker
    .register(swUrl)
    .catch((e) => ({ err: e }));
  if (reg.err || !reg) return console.error('[sw_reg] err', reg?.err || reg);

  //console.log('[sw_reg] reg', reg);

  //
  //
  //

  reg.onupdatefound = () => {
    const installingWorker = reg.installing;
    //console.log('[sw_reg_sub] low reg', reg);

    if (!installingWorker) return false;

    installingWorker.onstatechange = () => {
      //console.log('[sw_reg_sub] high', installingWorker.state, installingWorker);

      if (installingWorker.state !== 'installed') return false;

      if (navigator.serviceWorker.controller) {
        //console.log('[sw_reg_sub] Controller is here.\nSee https://cra.link/PWA.');
        if (config?.onUpdate) config.onUpdate(reg);

        askUserToUpdate(reg);
      } else {
        //console.log('[sw_reg_sub] Content is cached for offline use.');
        if (config?.onSuccess) config.onSuccess(reg);
      }
    };
  };

  reg.addEventListener('updatefound', (checkIt) => {
    //console.log('[sw_reg2] Service Worker update detected!\nAlert:', checkIt);
  });

  //
  //
  //

  // navigator.serviceWorker.ready.then((sreg)=>{
  // sreg.showNotification('ServiceWorker', {body: "I'm ready", tag: 'group_Notifs'});
  // });

  //
  //
  //

  // navigator.vibrate([100]); // message when you in the chat
  // navigator.vibrate([200]); // message, chat closed - regular vib i guess
  // navigator.vibrate([300]); // hey, there is something important
  // navigator.vibrate([400]); // long-danger warning
  // navigator.vibrate([100, 30, 100, 30, 100, 30, 200, 30, 200, 30, 200, 30, 100, 30, 100, 30, 100]);

  //
  //
  //

  if (reg.waiting) {
    askUserToUpdate(reg);
  }

  navigator.serviceWorker.addEventListener('waiting', (event) => {
    //console.log('[sw_reg2] waiting listener:', event);
    askUserToUpdate(event);
  });

  //
  //
  //

  thirdPartyButNot();
};

const askUserToUpdate = (reg) => {
  // const answer = window.confirm('Update PWA now?');
  // if(!answer)return false;

  navigator.serviceWorker.addEventListener('controllerchange', () =>
    window.location.reload(),
  );
  if (reg.waiting) reg.waiting.postMessage({ type: 'SKIP_WAITING' });
};

export { register, unregister };
