import React, { useEffect } from 'react';

import styles from '../ChatUserListContainer/ChatUserListContainer.module.scss';

import ellipsePic from '../../../../../../assets/picture/Ellipse 85.svg';

import _, { unescape } from 'lodash';
import { useGlobalContext } from '../../../../../../contexts/Context';
import useUsers from '../../../../../../hooks/useUsers';
import { useNavigate, useParams } from 'react-router-dom';

import defaultPic from '../../../../../../assets/picture/3 1.png';
import UserSharedWorker from '../../../../../../hooks/UserSharedWorker';
import useBasicFuncs from '../../../../../../hooks/useBasicFuncs';

function ChatGroupContainer(props) {
  const { groupName, id, membersCount, message, createdAt, type, groupItem } =
    props;

  const { user_id } = useParams();

  const GROUP__PHOTO = defaultPic;

  const { state, dispatch } = useGlobalContext();

  const { chatItems } = state;

  const navigate = useNavigate();

  const { messagesActionsAdd } = useUsers();

  const { sharedDataSingleUser } = UserSharedWorker();

  const { bdecode } = useBasicFuncs();

  useEffect(() => {
    groupItem?.users?.map((item) => {
      if (item.id === sharedDataSingleUser.chat_id) {
        if (item.message !== null) {
          item.message.message = sharedDataSingleUser.message;
          item.message.actions = null;
        }
      }
    });
  }, [sharedDataSingleUser]);

  const handleClick = (id, messageId) => {
    dispatch({
      type: 'MESSAGESLIST',
      payload: { chat_id: id },
    });

    dispatch({
      type: 'CHATMETADATA',
      payload: { offset: 0, limit: 10 },
    });

    if (messageId) {
      const isRead = 'seen';
      messagesActionsAdd(messageId, isRead, {}).then((data) => {
        if (data?.data) {
          const updatedChatItems = chatItems.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                message: {
                  ...item.message,
                  actions: 'seen',
                },
              };
            }
            return item;
          });

          dispatch({
            type: 'CHATITEMS',
            payload: updatedChatItems,
          });
        }
      });
    }

    if (!messageId) {
      const updatedChatItems = chatItems.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            message: {
              ...item.message,
              actions: 'seen',
            },
          };
        }
        return item;
      });

      dispatch({
        type: 'CHATITEMS',
        payload: updatedChatItems,
      });
    }

    navigate(`/chat/${id}`, { state: { groupItem, type } });
    // navigate(`/chat/${id}`);

    if (groupItem) {
      dispatch({
        type: 'CURRENTGROUPITEM',
        payload: groupItem,
      });
    }
  };

  return (
    <div
      className={
        id !== user_id
          ? styles.chatUserListContainer
          : `${styles.chatUserListContainer} ${styles.selectedUser}`
      }
      onClick={() => handleClick(id, message?.id)}
    >
      <div className={styles.chatUserListContainer_wrapper}>
        <div
          style={{
            visibility:
              groupItem?.message?.actions === null ? 'visible' : 'hidden',
          }}
          className={styles.isRead}
        >
          <img src={ellipsePic} alt="ellipse" />
        </div>
        <div className={styles.avatar}>
          <img src={GROUP__PHOTO} alt="chat_group_photo" />
        </div>
        <div className={styles.chatUserInfo}>
          <div>
            <span>{bdecode(groupName)}</span>

            <span className={styles.messageDateTime}>{createdAt}</span>
          </div>
          <span>{membersCount || 1} members </span>
          <span
            className={
              groupItem?.message?.actions === null
                ? `${styles.previewText} ${styles.previewTextBold}`
                : styles.previewText
            }
          >
            {React.createElement('div', {
              dangerouslySetInnerHTML: {
                __html: _.unescape(message?.message),
              },
            })}
          </span>
        </div>
      </div>
    </div>
  );
}

export default ChatGroupContainer;
