import { useState } from 'react';

import { SVGlupe, SVGbubble, SVGfilter } from '../../../assets/PackageSVG';
import LPButton from '../../ui/button/LPButton';

import cl from './FilterModules.module.scss';

const EventsFilterModule = ({ menuNotCollapsed, ...rest }) => {
  return (
    <>
      {menuNotCollapsed ? (
        <div className={cl.wrapper} {...rest}>
          <div className={cl.oneLine}>
            <label htmlFor="search">Search</label>
            <div className={cl.field}>
              <input placeholder="Search" id="search"></input>
              <SVGlupe className={cl.lupe}></SVGlupe>
            </div>
          </div>

          <div className={cl.inLine}>
            <LPButton
              onClick={() => console.log('[Save] not working, log only')}
            >
              Apply
            </LPButton>
            <LPButton
              onClick={() => console.log('[Save] not working, log only')}
              line
            >
              Save
            </LPButton>
          </div>
        </div>
      ) : (
        <div className={cl.collapsed} {...rest}>
          <div className={cl.item}>
            <SVGfilter></SVGfilter>
          </div>
        </div>
      )}
    </>
  );
};

export default EventsFilterModule;
