import React from 'react';
import useBasicFuncs from '../../hooks/useBasicFuncs';

import LPButton from '../../components/ui/button/LPButton';
import { SVGforSale, SVGrightArrow, SVGsold } from '../../assets/PackageSVG';
import { useNavigate } from 'react-router-dom';

import Image from '../../components/ui/image/Image';
import ProgressiveImg from '../../components/ui/image/ProgressiveImg';

import cl from './ListingsPage.module.scss';
import useUsers from '../../hooks/useUsers';
import { useDispatch } from 'react-redux';

const Listing = ({ props, lastListingRef }) => {
  const {
    image,
    title,
    price,
    shopsToShow,
    username,
    userPhoto,
    userId,
    listingStatus,
    placeholderSrc,
    listingId,
  } = props;
  const { getMpsImage, bdecode } = useBasicFuncs();
  const navigate = useNavigate();
  //
  //

  const dispatch = useDispatch();
  const { dmAdd, getUserListChats } = useUsers();

  const handleRedirectToChat = async () => {
    try {
      const response = await dmAdd(userId);

      if (response?.data) {
        const chat_id = response.data.id;

        dispatch({
          type: 'CHATMETADATA',
          payload: { offset: 0, limit: 10 },
        });

        dispatch({
          type: 'CHATNOTIFICATION',
          payload: false,
        });

        const options = {
          include_chat_users: true,
          include_last_message: true,
          include_last_message_actions: true,
        };
        const metadata = {
          offset: 0,
          limit: 10,
        };

        const filter_by = {
          chat_id,
        };

        const res = await getUserListChats(filter_by, options, metadata);
        if (res?.data) {
          const currentUser = res.data[0].users.filter(
            (user) => user.id === userId,
          );

          navigate(`/chat/${chat_id}`, {
            state: { chatUser: currentUser[0], title, listingId },
          });
        }
      }
    } catch (error) {
      console.error(error, 'error');
    }
  };

  return (
    <>
      <div className={cl.wrapper} ref={lastListingRef}>
        <div className={cl.block}>
          <div className={cl.image}>
            <ProgressiveImg
              src={image}
              placeholderSrc={placeholderSrc}
              alt="item for sale"
            />
          </div>
        </div>

        <div className={cl.line}>
          <div className={cl.title}>
            <p>{bdecode(title) || null}</p>
          </div>
        </div>

        <div className={cl.line}>
          <div className={cl.price}>
            <h3>${price ? (Math.round(price * 100) / 100).toFixed(2) : 0}</h3>

            {listingStatus ? <SVGsold /> : <SVGforSale />}
          </div>
        </div>

        <div className={cl.line}>
          <div className={cl.mpsDiv}>
            <div className={cl.mpsPool}>
              {shopsToShow && shopsToShow.length > 0 ? (
                shopsToShow.map(({ shop, link }) => (
                  <div
                    title={shop}
                    className={cl.mpImg}
                    key={shop}
                    onClick={() => {
                      window.open(link);
                    }}
                  >
                    <img src={getMpsImage(shop)} alt={`mp ${shop}`}></img>
                  </div>
                ))
              ) : (
                <p>No marketplaces added.</p>
              )}
            </div>
          </div>
        </div>
        <div className={cl.lastLine}>
          <div
            className={cl.left}
            onClick={() => navigate(`/profile/${userId}`)}
          >
            <Image className={cl.icon} src={userPhoto} alt="user" />
            <h3>{username}</h3>
          </div>
          <div className={cl.right}>
            <LPButton line className={cl.button} onClick={handleRedirectToChat}>
              <SVGrightArrow />
              <span>Message</span>
            </LPButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default Listing;
