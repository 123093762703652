import { useState } from 'react';
import useDelayUnmount from '../../../hooks/useDelayUnmount';

import { SVGtooltipfont } from '../../../assets/PackageSVG';

import cl from './Tooltip.module.scss';

const Tooltip = ({ className, title, text, children }) => {
  const [aShow, setShow] = useState(false);
  const anActualShow = useDelayUnmount(aShow, 500);

  if (!children && !title && !text) return null;
  else if (!children && !text && title) {
    text = title;
    title = '';
  }

  return (
    <>
      <div
        className={[className, cl.wrapper].join(' ')}
        onClick={() => setShow(!aShow)}
      >
        <div className={cl.icon}>
          <SVGtooltipfont fill="currentColor" width={20} height={20} />
        </div>
        {anActualShow ? (
          <div className={[aShow ? cl.on : cl.off, cl.tooltip].join(' ')}>
            <span className={cl.title}>{title}</span>
            <span className={cl.body}>{children ? children : text}</span>
          </div>
        ) : null}
      </div>
      {aShow ? (
        <div className={cl.clickToClose} onClick={() => setShow(!aShow)}></div>
      ) : null}
    </>
  );
};

export default Tooltip;
