import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
import { SVGchevronup } from '../../assets/PackageSVG';

import useUsers from '../../hooks/useUsers';

import Dropdown from '../../components/ui/dropdowns/Dropdown';
import DropdownWithInput from '../../components/ui/dropdowns/DropdownWithInput';
import LPButton from '../../components/ui/button/LPButton';
import DueDateDropdown from './DueDateDropdown';

import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import cl from './AddGoal.module.scss';

const goalCategories = [
  { value: 'Listing Prep', label: 'Listing Prep', valueKey: '1' },
  { value: 'Listing', label: 'Listing', valueKey: '2' },
  {
    value: 'Inventory Management',
    label: 'Inventory Management Goals',
    valueKey: '3',
  },
  { value: 'Sourcing', label: 'Sourcing Goals', valueKey: '4' },
  { value: 'Shipping', label: 'Shipping Goals', valueKey: '5' },
  { value: 'Social', label: 'Social Goals', valueKey: '6' },
  { value: 'Business Growth', label: 'Business Growth Goals', valueKey: '7' },
  { value: 'Personal', label: 'Personal', valueKey: '8' },
];

const goalTitles = [
  [
    { value: 'Listing Prep', label: 'Listing Prep', valueKey: '1' },
    { value: 'Photography', label: 'Photography', valueKey: '2' },
    {
      value: 'Cleaning / mending / steaming',
      label: 'Cleaning / mending / steaming',
      valueKey: '3',
    },
    { value: 'Measuring', label: 'Measuring', valueKey: '4' },
  ],
  [
    {
      value: 'Create / draft listing',
      label: 'Create / draft listing',
      valueKey: '1',
    },
    { value: 'Crossposting', label: 'Crossposting', valueKey: '2' },
    { value: 'Create templates', label: 'Create templates', valueKey: '3' },
    {
      value: 'Publish to a new marketplace',
      label: 'Publish to a new marketplace',
      valueKey: '4',
    },
  ],
  [
    { value: 'Manage SKU system ', label: 'Manage SKU system ', valueKey: '1' },
    {
      value: 'Send offers to likers / watchers',
      label: 'Send offers to likers / watchers',
      valueKey: '2',
    },
    {
      value: 'Send bundle offers (Poshmark)',
      label: 'Send bundle offers (Poshmark)',
      valueKey: '3',
    },
    {
      value: 'Delist, adjust your listings, relist',
      label: 'Delist, adjust your listings, relist',
      valueKey: '4',
    },
    {
      value: 'Take physical inventory',
      label: 'Take physical inventory',
      valueKey: '4',
    },
  ],
  [
    {
      value: 'Create sourcing plan ',
      label: 'Create sourcing plan ',
      valueKey: '1',
    },
    {
      value: 'Source items (garage sale, goodwill, pallet purchase, etc)',
      label: 'Source items (garage sale, goodwill, pallet purchase, etc)',
      valueKey: '2',
    },
    {
      value: 'Find wholesale / liquidators',
      label: 'Find wholesale / liquidators',
      valueKey: '3',
    },
    {
      value: 'Visit sourcing locations',
      label: 'Visit sourcing locations',
      valueKey: '4',
    },
    {
      value: 'Research online sourcing option',
      label: 'Research online sourcing option',
      valueKey: '5',
    },
  ],
  [
    { value: 'Order supplies', label: 'Order supplies', valueKey: '1' },
    { value: 'Pull sold items', label: 'Pull sold items', valueKey: '2' },
    { value: 'Prep shipping', label: 'Prep shipping', valueKey: '3' },
    {
      value: 'Have shipping ready (don’t miss the pickup window)',
      label: 'Have shipping ready (don’t miss the pickup window)',
      valueKey: '4',
    },
  ],
  [
    {
      value: 'Share your knowledge',
      label: 'Share your knowledge',
      valueKey: '1',
    },
    {
      value: 'Share your items to social outlets',
      label: 'Share your items to social outlets',
      valueKey: '2',
    },
    {
      value: 'Find and follow a new e-commerce expert (expand your knowledge) ',
      label: 'Find and follow a new e-commerce expert (expand your knowledge) ',
      valueKey: '3',
    },
    {
      value: 'Join a Listing Party',
      label: 'Join a Listing Party',
      valueKey: '4',
    },
    {
      value: 'Host a Listing Party',
      label: 'Host a Listing Party',
      valueKey: '5',
    },
    {
      value: 'Network with other resellers',
      label: 'Network with other resellers',
      valueKey: '6',
    },
    {
      value: 'Surround yourself with people that know more',
      label: 'Surround yourself with people that know more',
      valueKey: '7',
    },
  ],
  [
    {
      value: 'Higher average sale price',
      label: 'Higher average sale price',
      valueKey: '1',
    },
    { value: 'Increase catalog', label: 'Increase catalog', valueKey: '2' },
    {
      value: 'Expand marketplaces ',
      label: 'Expand marketplaces ',
      valueKey: '3',
    },
    {
      value: 'Keep accounting current',
      label: 'Keep accounting current',
      valueKey: '4',
    },
    {
      value: 'File quarterly taxes ',
      label: 'File quarterly taxes ',
      valueKey: '5',
    },
    {
      value: 'Find assistant in directory page ',
      label: 'Find assistant in directory page ',
      valueKey: '6',
    },
  ],
];

const AddGoal = ({ showAddGoal, setShowAddGoal, copyGoal }) => {
  const CHAR_LIMIT = 65;

  const [category, setCategory] = useState(null);
  const [goalTitle, setGoalTitle] = useState(null);
  const [quantity, setQuantity] = useState('');
  const [notes, setNotes] = useState('');
  const [startDate, setStartDate] = useState(new Date());

  const [dueDateOption, setDueDateOption] = useState(null);

  const [showMsgCategory, setShowMsgCategory] = useState('');
  const [showMsgTitle, setShowMsgTitle] = useState('');
  const [showMsgDueDate, setShowMsgDueDate] = useState('');

  const { goalsAdd } = useUsers();

  useEffect(() => {
    if (Object.keys(copyGoal).length > 0) {
      const { title, category, total, due_date, notes } = copyGoal;
      let categoryCapitalLetter = category
        .replace('_', ' ')
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

      setCategory({
        value: categoryCapitalLetter,
        label: categoryCapitalLetter,
      });
      setGoalTitle({ value: title, label: title });
      setQuantity(total);
      setNotes(notes);
      setDueDateOption({
        value: `Custom Due Date`,
        label: `Custom Due Date`,
        valueKey: '5',
      });

      let scrollDiv = document.getElementById('addGoal');
      window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
    }
  }, [copyGoal]);

  //
  //
  //set options for titles in relation to category
  const getRightOptions = (key) => {
    const index = key?.valueKey - 1;
    return goalTitles[index];
  };
  const rightOption = getRightOptions(category);

  //
  //
  //inputs set states
  const handleCategoryChange = (e) => {
    if (showMsgCategory) {
      setShowMsgCategory('');
    }
    setCategory(e);
  };

  const handleTitleChange = (e) => {
    if (showMsgTitle) {
      setShowMsgTitle('');
    }
    if (e?.value?.length > CHAR_LIMIT) {
      setShowMsgTitle('Max 65 characters');
      let trimmedStr = e.value.substring(0, 65);
      let title = { value: trimmedStr, label: trimmedStr };
      setGoalTitle(title);
      return;
    }
    setGoalTitle(e);
  };

  const handleQuantityChange = (value) => {
    setQuantity(value);
  };

  //
  //
  //actions
  const handleCancel = () => {
    setCategory('');
    setGoalTitle('');
    setQuantity('');
    setDueDateOption(null);
    setNotes('');
    setShowAddGoal(false);
  };

  const handleSave = () => {
    if (!category) {
      setShowMsgCategory('please add goal type before saving');
      return;
    }
    if (!goalTitle) {
      setShowMsgTitle('please add goal title before saving');
      return;
    }
    if (!dueDateOption) {
      setShowMsgDueDate('please add due date before saving');
      return;
    }
    const goal = {
      type: category.value.toLowerCase().replace(' ', '_'),
      goal: goalTitle.value,
      total_quantity: parseInt(quantity) || 1,
      progress: [],
      notes: notes || '',
    };
    const { value } = dueDateOption;
    const due_date = {
      due_date: new Date(value === 'Custom Due Date' ? startDate : value),
    };
    const goalStatus = { status: 'active' };
    goalsAdd(goal, due_date, goalStatus).then(
      ({ data }) => {
        const msg = `Goal: "${data?.data?.goal}" saved!`;
        notifySaving(msg);
        window.location.reload();
      },
      (error) => {
        console.error('Error saving goal:', error);
        notifyError();
      },
    );
    setCategory('');
    setGoalTitle('');
    setQuantity('');
    setNotes('');
  };

  const notifySaving = (msg) => {
    toast(msg, {
      position: 'bottom-right',
      autoClose: 5000,
      draggable: true,
      pauseOnFocus: true,
      pauseOnHover: true,
    });
  };
  const notifyError = () => {
    toast.warn('Error saving data.', {
      position: 'bottom-right',
      autoClose: 5000,
      draggable: true,
      hideProgressBar: true,
    });
  };

  return (
    <>
      {showAddGoal ? (
        <div className={cl.addGoalContainer} id="addGoal">
          <div className={cl.header}>
            Add new goal
            <SVGchevronup
              fill="#173593"
              width={20}
              height={20}
              onClick={() => handleCancel()}
            />
          </div>
          <div className={cl.inputsDiv}>
            <div className={cl.inputs}>
              <div>
                <Dropdown
                  placeholder="Select Goal Type"
                  options={goalCategories}
                  value={category}
                  handleChange={(e) => handleCategoryChange(e)}
                  isClearable={true}
                  isSearchable={true}
                />
                {showMsgCategory && (
                  <div className={cl.msg}>{showMsgCategory}</div>
                )}
              </div>

              <div>
                <DropdownWithInput
                  placeholder="Select or Add Title"
                  options={rightOption}
                  handleChange={(e) => handleTitleChange(e)}
                  value={goalTitle}
                  isClearable={true}
                />
                {showMsgTitle && <div className={cl.msg}>{showMsgTitle}</div>}
              </div>
            </div>
            <div className={cl.inputs}>
              <input
                type="number"
                placeholder="Quantity"
                value={quantity}
                min="0"
                onChange={({ target }) => handleQuantityChange(target.value)}
              ></input>
            </div>
            <div className={cl.dueDatePicker}>
              <DueDateDropdown
                dueDateOption={dueDateOption}
                setDueDateOption={setDueDateOption}
                startDate={startDate}
                setStartDate={setStartDate}
              />
              {showMsgDueDate && <div className={cl.msg}>{showMsgDueDate}</div>}
            </div>
            <div className={cl.notes}>
              <textarea
                type="text"
                maxLength="80"
                placeholder="Notes, max 80 characters"
                value={notes}
                onChange={({ target }) => setNotes(target.value)}
              ></textarea>
            </div>
          </div>
          <div className={cl.buttons}>
            <LPButton line className={cl.saveBtn} onClick={handleCancel}>
              Clear and Close
            </LPButton>
            <LPButton line className={cl.saveBtn} onClick={handleSave}>
              Save
            </LPButton>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default AddGoal;
