import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useUsers from '../../../hooks/useUsers';
import { userDataSelector, userTypesSelector } from '../../../redux/selectors';
import { setUserTypes } from '../../../redux/slices/userTypesSlice';

import Input from '../../ui/input/Input';

import cl from './Checkbox.module.scss';

const Checkbox = ({
  label,
  prefClass,
  data,
  setData,
  setSaveBtnDisabled,
  type,
  usertypesSection,
  setShowMsg,
  setLoading,
}) => {
  const [boxChecked, setBoxChecked] = useState(false);

  const dispatch = useDispatch();
  const userTypes = useSelector(userTypesSelector);
  const userData = useSelector(userDataSelector);

  const { usersUpdate } = useUsers();

  const checkboxClass = [
    cl.checkmarkBox,
    boxChecked ? [cl.active, cl[[type]]].join(' ') : '',
    prefClass ? cl.pref : '',
    label === 'Buyer' ? cl.hidden : '',
  ].join(' ');

  const handleChange = ({ target: { checked } }) => {
    if (!usertypesSection)
      setSaveBtnDisabled((prev) => ({ ...prev, [type]: false })); // usertypes section is on autosave, no need to reset btn
    setBoxChecked(checked);

    //
    //
    //

    if (usertypesSection) {
      if (checked) {
        if (!userTypes.includes(label.toLowerCase())) {
          dispatch(setUserTypes([...userTypes, label.toLowerCase()]));
        }
      } else {
        let newTypes = userTypes.filter((type) => type !== label.toLowerCase());

        dispatch(setUserTypes(newTypes));
      }
      const currentTypes = userTypes.includes(label.toLowerCase())
        ? userTypes.filter((type) => type !== label.toLowerCase())
        : [...userTypes, label.toLowerCase()];
      setLoading(true);
      usersUpdate({ payload: { types: currentTypes }, id: userData.id }).then(
        () => {
          setLoading(false);
          let currentTypesStr = currentTypes.toString().replace(/,/gi, ', ');
          let msg = userTypes.includes(label.toLowerCase())
            ? `We will HIDE your ${label} profile from other partiers.`
            : `Your ${currentTypesStr} profile will be shared with other partiers!`;
          setShowMsg(msg);
        },
        (error) => {
          console.error('Error saving data:', error);
          setLoading(false);
          setShowMsg('Error saving data.');
        },
      );
    }

    //
    //
    //
    else {
      if (checked) {
        if (!data.includes(label.toLowerCase())) {
          setData((prev) => [...prev, label.toLowerCase()]);
        }
      } else {
        if (data && data.length > 0) {
          const updatedLocatPref = data.filter(
            (locat) => locat !== label.toLowerCase(),
          );
          setData(updatedLocatPref);
        }
      }
    }
  };

  useEffect(() => {
    if (userTypes) {
      if (userTypes.length > 0)
        userTypes.includes(label.toLowerCase()) && setBoxChecked(true);
    }
  }, [userTypes]);

  useEffect(() => {
    if (data && data.length > 0) {
      data.includes(label.toLowerCase()) && setBoxChecked(true);
    }
  }, [data]);

  return (
    <>
      <div className={checkboxClass}>
        <label
          htmlFor={`checkbox${type}${label}`}
          className={cl.checkmarkLabel}
        >
          {label}
          {label === 'Other' ? (
            <Input
              addClass="otherType"
              maxLength="32"
              required={false}
              name="otherType"
              placeholder="Type Here"
            />
          ) : null}
          <input
            id={`checkbox${type}${label}`}
            type="checkbox"
            className={[cl.checkmark, cl[[type]]].join(' ')}
            checked={boxChecked}
            onChange={handleChange}
          />
        </label>
      </div>
    </>
  );
};

export default Checkbox;
