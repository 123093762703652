import React, { useEffect, useState } from 'react';

import { SVGimagefont } from '../../../assets/PackageSVG';

import cl from './ProgressiveImg.module.scss';

const ProgressiveImg = ({ placeholderSrc, src, ...props }) => {
  const [imgSrc, setImgSrc] = useState(placeholderSrc || src);
  const [customClass, setcustomClass] = useState(
    placeholderSrc && imgSrc === placeholderSrc ? 'loading' : '',
  );

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImgSrc(src);
      setcustomClass('loaded');
    };

    return img.removeEventListener('load', () => {
      setImgSrc(src);
      setcustomClass('loaded');
    });
  }, [src]);

  return (
    <>
      {src ? (
        <img
          {...{ src: imgSrc, ...props }}
          className={cl[customClass]}
          alt={props.alt || ''}
          // style={{backgroundImage: `url(${loadingImage})`, backgroundSize: 'cover', width: '300px', height: '300px'}}
        />
      ) : (
        <div>
          <SVGimagefont fill="#b1b1b1" />
          <span>Image coming soon</span>
        </div>
      )}
    </>
  );
};
export default ProgressiveImg;
