import { useState, useEffect } from 'react';

const minWidthToMobile = 768;
const minWidthCollapseMenu = 988;

const checkForDevice = (w) => w < minWidthToMobile;
const checkForCollapse = (w) => w < minWidthCollapseMenu;

const useScreenSize = () => {
  const [isMobile, setIsMobile] = useState(checkForDevice(window.innerWidth));
  const [isCollapsible, setisCollapsible] = useState(
    checkForCollapse(window.innerWidth),
  );
  useEffect(() => {
    const handlePageResized = () => {
      setIsMobile(checkForDevice(window.innerWidth));
      setisCollapsible(checkForCollapse(window.innerWidth));
    };

    window.addEventListener('resize', handlePageResized);
    window.addEventListener('orientationchange', handlePageResized);
    window.addEventListener('load', handlePageResized);
    window.addEventListener('reload', handlePageResized);

    return () => {
      window.removeEventListener('resize', handlePageResized);
      window.removeEventListener('orientationchange', handlePageResized);
      window.removeEventListener('load', handlePageResized);
      window.removeEventListener('reload', handlePageResized);
    };
  }, []);

  return {
    isMobile,
    isCollapsible,
  };
};

export default useScreenSize;
