import React from 'react';
import cl from './SmallSpinner.module.scss';

const SmallSpinner = () => {
  return (
    <>
      <div className={cl.spinner}></div>
    </>
  );
};

export default SmallSpinner;
