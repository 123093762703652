import cl from './LPButton.module.scss';

//
//
//

const LPButton = ({ className, line, title, ...rest }) => {
  const classes = [cl.btn];

  if (line) classes.push(cl.lineButton);

  classes.push(className);

  return (
    <>
      <button {...rest} className={classes.join(' ')} title={title}></button>
    </>
  );
};

//
//
//

export default LPButton;
