import { useContext, useReducer, createContext } from 'react';

export const Acontext = createContext();
export const useGlobalContext = () => useContext(Acontext);

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'BLOCK':
      localStorage.ban = payload?.ban;
      localStorage.noUsername = payload?.noUsername;
      return { ...state, blockState: payload };
    case 'MEDIATOR':
      return { ...state, mediator: { ...payload } };
    case 'SETINITIALSLIDE':
      return { ...state, initialSlide: payload };
    case 'SETINDEX':
      return { ...state, sliderIndex: payload };
    case 'NAVPREV':
    case 'PREVNAV':
    case 'PREV_NAV':
      return { ...state, prevnav: { ...payload } };
    case 'TOKEN':
      return { ...state, ...payload };
    case 'TYPES':
      return { ...state, userTypes: payload };
    case 'USERDATA':
      return { ...state, ...payload };
    case 'FILTERS':
      return { ...state, filters: { ...payload } };
    case 'METADATA':
      return { ...state, metadata: { ...payload } };
    //
    //
    case 'CHATMETADATA':
      return { ...state, chatMetadata: { ...payload } };
    case 'SHOWLINK':
      return { ...state, showlink: payload };
    case 'TERMSMODAL':
      return { ...state, showModal: payload };
    case 'MESSAGESLIST':
      return { ...state, messagesListFilters: payload };

    case 'SELECTEDCHATUSER':
      return { ...state, selectedChatUser: payload };

    case 'CHATUSERS':
      return { ...state, chatUsers: payload };

    case 'CHATITEMS':
      return { ...state, chatItems: payload };

    case 'GROUPMEMBERS':
      return { ...state, groupMembers: payload };

    case 'CHATNOTIFICATION':
      return { ...state, chatNotification: payload };
    //
    case 'EVENTSTOTAL':
      return { ...state, eventsTotal: { ...state.eventsTotal, ...payload } };

    case 'CURRENTGROUPITEM':
      return { ...state, currentGroupItem: payload };

    case 'ISROLEALLOWED':
      return { ...state, isRoleAllowed: payload };

    default:
      return state;
  }
};

const initial_store_values = {
  isProd: process.env.NODE_ENV === 'production',
  baseApiUrl: 'https://api.listperfectly.com/v1',
  baseAwsUrl: 'https://s3.amazonaws.com/cdn.listingparty.com',
  //
  blockState: {
    ban: localStorage.ban === 'true',
    noUsername: localStorage.noUsername === 'true',
  },
  sliderIndex: 0,
  token: '',
  isAuthorized: false,
  //
  userTypes: [],
  initialSlide: 0,
  //
  mediator: {},
  prevnav: {},
  showModal: false,
  showlink: '',
  //
  userData: {},
  //
  filters: {},
  metadata: { offset: 0, limit: 10 },
  chatMetadata: { offset: 0, limit: 10 },
  //
  //
  messagesListFilters: {},
  selectedChatUser: false,
  //
  //
  chatUsers: [],
  chatNotification: false,
  //
  eventsTotal: {},

  chatItems: [],
  groupMembers: [],
  currentGroupItem: null,
  isRoleAllowed: false,
};

const Context = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initial_store_values);

  return (
    <Acontext.Provider value={{ state, dispatch }}>
      {children}
    </Acontext.Provider>
  );
};

export default Context;
