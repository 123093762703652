import React from 'react';

import { useNavigate } from 'react-router-dom';

import {
  SVGdirectory,
  SVGprofile,
  SVGlistings,
  SVGparties,
} from '../../assets/PackageSVG';
import LPButton from '../../components/ui/button/LPButton';
import Container from '../../components/Container/Container';
import Padlock from '../../components/ui/padlock/Padlock';

import cl from './DashboardPage.module.scss';

const DashboardCard = ({
  page,
  title,
  description,
  btnText,
  link,
  disabled,
}) => {
  const navigate = useNavigate();
  return (
    <Container
      className={[
        cl.cardContainer,
        disabled && page !== 'profile' && page !== 'video' && cl.disabled,
        cl[[page]],
      ].join(' ')}
    >
      <div className={[cl.left, cl[[page]]].join(' ')}>
        {page === 'directory' ? (
          <SVGdirectory
            className={[cl.icon, cl[[page]]].join(' ')}
          ></SVGdirectory>
        ) : page === 'profile' ? (
          <SVGprofile className={[cl.icon, cl[[page]]].join(' ')}></SVGprofile>
        ) : page === 'listings' ? (
          <SVGlistings
            className={[cl.icon, cl[[page]]].join(' ')}
          ></SVGlistings>
        ) : page === 'events' ? (
          <SVGparties className={[cl.icon, cl[[page]]].join(' ')}></SVGparties>
        ) : null}
      </div>
      <div className={[cl.middle, cl[[page]]].join(' ')}>
        <div>
          <h2>{title}</h2>
        </div>
        <div>
          <p>{description}</p>
        </div>
        {page === 'video' ? (
          <div className={cl.videoContainer}>
            <iframe
              width="90%"
              height="350px"
              src={`https://www.youtube.com/embed/ABxE5JQxqu0`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Youtube video"
            />
          </div>
        ) : null}
      </div>
      {page !== 'video' ? (
        <div className={cl.right}>
          {disabled && page !== 'profile' && (
            <div className={cl.padlock}>
              <Padlock
                text="Complete your profile to unlock"
                usernameDisabled={true}
              />
            </div>
          )}

          <LPButton className={cl[[page]]} onClick={() => navigate(link)}>
            {btnText}
          </LPButton>
        </div>
      ) : null}
    </Container>
  );
};

export default DashboardCard;
