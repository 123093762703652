import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userTypes: [],
};

const userTypesSlice = createSlice({
  name: 'userTypes',
  initialState,
  reducers: {
    setUserTypes: (state, action) => {
      state.userTypes = action.payload;
    },
  },
});

export const { setUserTypes } = userTypesSlice.actions;
export default userTypesSlice.reducer;
