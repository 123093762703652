import React, { useEffect, useRef } from 'react';
import { SVGlive } from '../../../../assets/PackageSVG';
import styles from './EventModal.module.scss';
import useBasicFuncs from '../../../../hooks/useBasicFuncs';
import AddCalendar from '../AddCalendar';
import moment from 'moment';

import { Link } from 'react-router-dom';
import {
  SVGCloseIcon,
  SVGcalendaralt,
  SVGcircleuser,
} from '../../../../assets/PackageSVG';

import { useDispatch } from 'react-redux';
import { setShowModal } from '../../../../redux/slices/eventTermsModalSlice';

function EventModal(props) {
  const { calendarItems, setCalendarItems, tab } = props;

  const { data, date, title, hosts } = calendarItems;

  const { description, link, recording_links, thumbnail } = data;

  const { bdecode } = useBasicFuncs();

  const dispatch = useDispatch();

  const currentDate = Date.now();
  const eventDate = new Date(date).getTime();
  const live =
    eventDate - currentDate <= 600000 && currentDate - eventDate <= 7.2e6
      ? true
      : false;

  const correctDate = moment(date).format('MMMM Do, h:mm a');
  const day = moment(date).format('dddd');
  const recording =
    recording_links && recording_links.length > 0 ? recording_links[0] : null;

  const hostData =
    hosts?.length > 0
      ? hosts.map(({ user_id, username }) => {
          return (
            <Link key={user_id} to={`/profile/${user_id}`}>
              @{username}
            </Link>
          );
        })
      : null;

  const eventModalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        eventModalRef.current &&
        !eventModalRef.current.contains(event.target)
      ) {
        setCalendarItems(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [eventModalRef]);

  return (
    <div className={styles.overlay}>
      <div ref={eventModalRef} className={styles.modal}>
        <div
          onClick={() => setCalendarItems(null)}
          className={styles.closeModal}
        >
          <SVGCloseIcon />
        </div>
        <div className={styles.modal__wrapper}>
          <div className={styles.modalHeaderBlock}>
            <div className={styles.modalThumbnail}>
              <img src={thumbnail} alt="" />
              {live ? <SVGlive /> : ''}
            </div>
            <div className={styles.modalInfo}>
              <h1>{title}</h1>
              <div>
                <span>
                  <SVGcircleuser width={16} height={16} />
                </span>
                <span>{hostData}</span>
              </div>
              <div>
                <span>
                  <SVGcalendaralt fill="#173593" width={16} height={16} />
                </span>
                <span className={styles.date}>{correctDate}</span>
              </div>
            </div>
          </div>

          <p className={styles.desc}>{bdecode(description)}</p>

          <div className={styles.eventModalBtns}>
            {live ? (
              <button
                onClick={() => {
                  if (live) {
                    dispatch(setShowModal(true));
                  }
                  if (live && tab === 'upcoming') {
                    let linkToShow = link.includes('https://')
                      ? link
                      : `https://${link}`;
                    dispatch({
                      type: 'SHOWLINK',
                      payload: linkToShow,
                    });
                  } else if (recording && tab === 'past') {
                    let linkToShow = recording.includes('https://')
                      ? recording
                      : `https://${recording}`;
                    dispatch({
                      type: 'SHOWLINK',
                      payload: linkToShow,
                    });
                  }
                }}
                className={styles.joinPartyButton}
              >
                Join party
              </button>
            ) : (
              <AddCalendar
                title={title}
                description={description}
                date={date}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventModal;
