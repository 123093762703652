import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showModal: false,
  showlink: '',
};

const eventTermsModalSlice = createSlice({
  name: 'eventTermsModal',
  initialState,
  reducers: {
    setShowModal: (state, action) => {
      state.showModal = action.payload;
    },
    setShowLink: (state, action) => {
      state.showlink = action.payload;
    },
  },
});

export const { setShowModal, setShowLink } = eventTermsModalSlice.actions;
export default eventTermsModalSlice.reducer;
