import React, { useState, useEffect, useRef } from 'react';

import _ from 'lodash';

import useUsers from '../../hooks/useUsers';
import usePrompt from '../../hooks/usePrompt';

import AboutmeSect from './aboutmeSection/AboutmeSect';
import Container from '../../components/Container/Container.jsx';
import Loader from '../../components/Container/Loader';
import UsertypesSect from './usertypesSection/UsertypesSect';
import ConsignerAssistSellerSect from './consignerAssistSellerSection/ConsignerAssistSellerSect';
import Wrapper from './Wrapper';

import cl from './EditProfile.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { mediatorSelector, userTypesSelector } from '../../redux/selectors';
import { setUserTypes } from '../../redux/slices/userTypesSlice';
import { setMediator } from '../../redux/slices/mediatorSlice';
import { setUserData } from '../../redux/slices/userDataSlice';

const ProfileForm = () => {
  const dispatch = useDispatch();

  const userTypes = useSelector(userTypesSelector);

  const { usersSelf } = useUsers();

  //
  //
  //

  const [loading, setLoading] = useState(true);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState({
    global: true,
    assistant: true,
    seller: true,
    consigner: true,
    aboutme: true,
  });

  useEffect(() => {
    const arr = [{ to: '/profile/edit#profile', name: 'About me' }];
    if (!loading)
      arr.push({ to: '/profile/edit#types', name: 'What do you do?' });

    if (!loading && userTypes?.length > 0) {
      if (userTypes.includes('seller'))
        arr.push({ to: '/profile/edit#seller', name: 'Seller' });
      if (userTypes.includes('assistant'))
        arr.push({ to: '/profile/edit#assistant', name: 'Assistant' });
      if (userTypes.includes('consigner'))
        arr.push({ to: '/profile/edit#consigner', name: 'Consigner' });
    }

    dispatch(setMediator({ who: 'nav', for: '/profile/edit', arr }));
  }, [loading, userTypes, dispatch]);

  useEffect(() => {
    try {
      usersSelf().then(({ data }) => {
        if (!data) return console.log('error fetching user data'); //make toastify
        const { types = [] } = data;
        if (!data.types) data.types = [];
        if (!data.types_metadata) data.types_metadata = {};
        if (!data.types_metadata.assistant) data.types_metadata.assistant = {};

        dispatch(setUserTypes(types));

        dispatch(setUserData({ userData: data }));

        setLoading(false);
      });
    } catch (err) {
      console.error('Error fetching user data from db', err);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(saveBtnDisabled).some((k) => !saveBtnDisabled[k])) {
      window.onbeforeunload = function () {
        if (!Object.keys(saveBtnDisabled).some((k) => !saveBtnDisabled[k])) {
          return 'Changes you made may not be saved. Still leave page?';
        }
      };
    }
  }, [saveBtnDisabled]);

  usePrompt(
    'Changes you made may not be saved. Still leave page?',
    Object.keys(saveBtnDisabled).some((k) => !saveBtnDisabled[k]),
  );

  return (
    <>
      <Loader isLoading={loading}>
        <Container id="profile" className={cl.formSmallContainer}>
          <AboutmeSect {...{ setSaveBtnDisabled, saveBtnDisabled }} />
        </Container>
        <Container id="types" className={cl.formSmallContainer}>
          <UsertypesSect {...{ setSaveBtnDisabled }} />
        </Container>
        {userTypes && userTypes.includes('seller') && (
          <Container
            className={cl.profileContainer}
            id="seller"
            title="Seller"
            titleColor="green"
          >
            <Wrapper>
              <ConsignerAssistSellerSect
                type="seller"
                title="Seller"
                {...{ setSaveBtnDisabled, saveBtnDisabled }}
              />
            </Wrapper>
          </Container>
        )}
        {userTypes && userTypes.includes('assistant') && (
          <Container
            id="assistant"
            className={cl.profileContainer}
            title="Assistant"
            titleColor="blue"
          >
            <Wrapper>
              <ConsignerAssistSellerSect
                type="assistant"
                title="Assistant"
                {...{ setSaveBtnDisabled, saveBtnDisabled }}
              />
            </Wrapper>
          </Container>
        )}
        {userTypes && userTypes.includes('consigner') && (
          <Container
            id="consigner"
            className={cl.profileContainer}
            title="Consigner"
            titleColor="purple"
          >
            <Wrapper>
              <ConsignerAssistSellerSect
                type="consigner"
                title="Consigner"
                {...{ setSaveBtnDisabled, saveBtnDisabled }}
              />
            </Wrapper>
          </Container>
        )}
      </Loader>
    </>
  );
};

export default ProfileForm;
