import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { setAuth } from '../redux/slices/authSlice';
import { isAuthorizedSelector } from '../redux/selectors';

const getToken = () => {
  try {
    const url = window.location.href;
    const index = url.indexOf('token=');

    if (index === -1) throw new Error('No token attached');

    return url.slice(index + 6, url.length);
  } catch (e) {
    console.error('[getToken] err', e.message);
    return '';
  }
};

const Authentication = () => {
  const dispatchRedux = useDispatch();

  const navigate = useNavigate();

  const isAuthorized = useSelector(isAuthorizedSelector);

  //

  useEffect(() => {
    if (isAuthorized) navigate('/profile');

    const token = getToken();
    if (!token && process.env.NODE_ENV === 'production') {
      localStorage.lp_custom_access_token = '';

      dispatchRedux(setAuth({ token: '', isAuthorized: false }));

      navigate('/login');
    } else if (token) {
      localStorage.lp_custom_access_token = token;

      dispatchRedux(setAuth({ token, isAuthorized: true }));
      navigate('/');
    }
  }, []);

  return <></>;
};

export default Authentication;
