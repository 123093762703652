import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import styles from './ChatUsers.module.scss';

import useUsers from '../../../../hooks/useUsers';

import moment from 'moment/moment';

import { ChatUserListContainer } from './components/ChatUserListContainer/ChatUserListContainer';
import UserSharedWorker from '../../../../hooks/UserSharedWorker';
import { useGlobalContext } from '../../../../contexts/Context';
import { SVGgroup } from '../../../../assets/PackageSVG';
import CreateGroupModal from '../ChatMessages/components/CreateGroupModal/CreateGroupModal';
import ChatGroupContainer from './components/ChatGroupContainer';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { isRoleAllowedSelector } from '../../../../redux/selectors';

const ChatUsers = () => {
    const [users, setUsers] = useState([]);
    const [hasDataCome, setHasDataCome] = useState(false);
    const [metadata, setMetadata] = useState({ offset: 0, limit: 10 });
    const [total, setTotal] = useState(0);
    const [filterBy, setFilterBy] = useState({});
    const [options] = useState({
        include_chat_users: true,
        include_last_message: true,
        include_last_message_actions: true,
    });
    const [isFetching, setIsFetching] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const [createModalOpen, setCreateModalOpen] = useState(false);

    const [userAdata, setUserAdata] = useState('');

    const { sharedDataSingleUser } = UserSharedWorker();

    const { getUserListChats, usersSelf, dmAdd, usersList, groupsAdd } =
        useUsers();

    const { dispatch, state } = useGlobalContext();

    const { chatItems } = state;

    const isRoleAllowed = useSelector(isRoleAllowedSelector);


    const navigate = useNavigate();

    const getChatUsers = () => {
        try {
            getUserListChats(filterBy, options, metadata).then((data) => {
                if (data.data) {
                    dispatch({
                        type: 'CHATITEMS',
                        payload: data.data,
                    });
                }
            });
        } catch (error) {
            console.error(error);
        }
    };

    const getUsersSelf = () => {
        usersSelf().then((data) => {
            if (data.data) {
                setUserAdata(data.data);
            }
        });
    };

    useEffect(() => {
        getUsersSelf();
        getChatUsers();
    }, []);

    const observer = useRef(null);

    const lastUserContainer = useCallback(
        (node) => {
            if (isFetching) return;

            if (observer.current) observer.current.disconnect();

            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    let updOffset = metadata.offset + 10;

                    setMetadata((prev) => ({ ...prev, offset: updOffset }));
                }
            });

            if (node) observer.current.observe(node);
        },
        [isFetching, hasMore]
    );

    useEffect(() => {
        if (sharedDataSingleUser.chat_id !== undefined) {
            const userIndex = chatItems.findIndex(
                (chatItem) => chatItem.id === sharedDataSingleUser.chat_id
            );

            if (userIndex !== -1) {
                const user = chatItems.splice(userIndex, 1)[0];

                dispatch({
                    type: 'CHATITEMS',
                    payload: [user, ...chatItems],
                });
            } else {
                getTypeToChat().then((chatType) => {
                    if (chatType === 'group') {
                        addGroupToChat();
                    } else {
                        addUserToChat();
                    }
                });
            }
        }
    }, [sharedDataSingleUser]);

    async function addGroupToChat() {
        try {
            const response = await getUserListChats(
                { id: sharedDataSingleUser.chat_id },
                options,
                metadata
            );

            if (response.data) {
                const [temp] = response.data;

                dispatch({
                    type: 'CHATITEMS',
                    payload: [temp, ...chatItems],
                });
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function addUserToChat() {
        try {
            const filterBy = { id: sharedDataSingleUser.user_id };
            const metadata = { offset: 0, limit: 1 };
            const response = await usersList(filterBy, metadata);
            const chatUser = response.data;

            const user_id = sharedDataSingleUser.user_id;

            const res = await dmAdd(user_id);

            if (chatItems.length === 0 && res?.data) {
                const chat_id = res.data.id;

                dispatch({
                    type: 'CHATMETADATA',
                    payload: { offset: 0, limit: 10 },
                });

                dispatch({
                    type: 'MESSAGESLIST',
                    payload: { chat_id: chat_id },
                });

                navigate(`/chat/${chat_id}`, {
                    state: { chatUser: chatUser[0], type: 'dm' },
                });
            }

            const temp = {
                id: res.data.id,
                updated_at: res.data.updated_at,
                created_at: res.data.created_at,
                type: res.data.type,

                message: {
                    message: sharedDataSingleUser.message,
                },
                users: [chatUser[0]],
            };

            dispatch({
                type: 'CHATITEMS',
                payload: [temp, ...chatItems],
            });
        } catch (error) {
            console.error(error);
        }
    }

    async function getTypeToChat() {
        try {
            const response = await getUserListChats(
                { id: sharedDataSingleUser.chat_id },
                options,
                metadata
            );

            if (response.data) {
                const [chatItem] = response.data;
                const { type } = chatItem;

                return type;
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleToggleCreateModalOpen = () => {
        setCreateModalOpen(!createModalOpen);
    };

    const loadMoreData = () => {
        // Update your metadata to load the next batch of data
        const updatedOffset = metadata.offset + 10;
        const localmetadata = { ...metadata, offset: metadata.offset + 10 };
        setMetadata((prev) => ({ ...prev, offset: updatedOffset }));

        getUserListChats(filterBy, options, localmetadata)
            .then((data) => {
                if (data?.data) {
                    // Append the new data to your chatItems
                    dispatch({
                        type: 'CHATITEMS',
                        payload: [...chatItems, ...data.data],
                    });

                    // Check if there's more data available
                    if (data.data.length === 0) {
                        setHasMore(false);
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <>
            {createModalOpen && (
                <CreateGroupModal
                    handleToggleCreateModalOpen={handleToggleCreateModalOpen}
                    role="create"
                />
            )}

            <div className={styles.chatUsers}>
                <div className={styles.chatUsersTitle}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                        <h2>Messages</h2>
                        {total !== 0 && (
                            <div className={styles.chatUsersCount}>
                                <span>{total}</span>
                            </div>
                        )}
                    </div>

                    {isRoleAllowed && (
                        <div
                            onClick={handleToggleCreateModalOpen}
                            className={styles.chatGroup}
                        >
                            <SVGgroup />
                        </div>
                    )}
                </div>
                <div className={styles.chatUsersList}>
                    <InfiniteScroll
                        dataLength={chatItems.length}
                        height={'80vh'}
                        style={{ paddingRight: '10px' }}
                        next={loadMoreData}
                        scrollThreshold={1}
                        hasMore={hasMore}
                        loader={<h4>Loading...</h4>}
                    >
                        {chatItems?.map((item, index) =>
                            item.type === 'dm' ? (
                                item?.users
                                    .filter((fItem) => fItem.id !== userAdata.id)
                                    ?.map((fItem, index) =>
                                        fItem.length !== index + 1 ? (
                                            <ChatUserListContainer
                                                key={item.id}
                                                id={item.id}
                                                chatUser={fItem}
                                                message={item?.message}
                                                type={item?.type}
                                                createdAt={ _.get(item, 'message.created_at') ? moment( _.get(item, 'message.created_at') ).format('h:mm A') : ''}
                                                chatItem={item}
                                            />
                                        ) : (
                                            <div key={item.id}>
                                                <ChatUserListContainer
                                                    key={item.id}
                                                    id={item.id}
                                                    chatUser={fItem}
                                                    message={item?.message}
                                                    type={item?.type}
                                                    createdAt={ _.get(item, 'message.created_at') ? moment( _.get(item, 'message.created_at') ).format('h:mm A') : ''}
                                                    chatItem={item}
                                                />
                                                <div className="here!" ref={lastUserContainer}></div>
                                            </div>
                                        )
                                    )
                            ) : item.length !== index + 1 ? (
                                <ChatGroupContainer
                                    key={item.id}
                                    id={item.id}
                                    groupName={item.name}
                                    membersCount={item.users?.length}
                                    message={item.message}
                                    createdAt={ _.get(item, 'message.created_at') ? moment( _.get(item, 'message.created_at') ).format('h:mm A') : ''}
                                    type={item?.type}
                                    groupItem={item}
                                />
                            ) : (
                                <div key={item.id}>
                                    <ChatGroupContainer
                                        key={item.id}
                                        id={item.id}
                                        groupName={item.name}
                                        membersCount={item.users?.length}
                                        message={item.message}
                                        createdAt={ _.get(item, 'message.created_at') ? moment( _.get(item, 'message.created_at') ).format('h:mm A') : ''}
                                        type={item?.type}
                                        groupItem={item}
                                    />
                                    <div className="here!" ref={lastUserContainer}></div>
                                </div>
                            )
                        )}
                    </InfiniteScroll>
                </div>
            </div>
        </>
    );
};

export default memo(ChatUsers);